import { graphql } from 'msw';
import {
  ActivityImpactEnum,
  AddDiscloserListOrganisationsToTrackingPortfolioMutation,
  AddOrganisationToProjectInstanceMutation,
  AddToDiscloserListMutation,
  CheckIfCanBeScoredQuery,
  CheckIfOrgsInTheListQuery,
  CheckIfOrgsInTheProjectQuery,
  CreateDiscloserListMutation,
  CreateInvitationMutation,
  CreateProjectMutation,
  CreateQuestionnaireSetupMutation,
  CreateTrackingPortfolioMutation,
  CreateUserWithInviteMutation,
  DelegateRequestMutation,
  DeleteOrganisationsFromSampleListMutation,
  DeleteProjectInstanceMutation,
  DeleteProjectMutation,
  DeleteSampleMutation,
  DeleteTrackingPortfolioMutation,
  DisableUserMutation,
  ExtendedOrgSearchQuery,
  GetActiveDisclosureCycleForDiscloserQuery,
  GetActiveDisclosureCycleQuery,
  GetActiveRequestersQuery,
  GetAllAuthorityDiscloserListsQuery,
  GetAllIndustriesQuery,
  GetAllTagCollectionsQuery,
  GetAllTagsQuery,
  GetAuthorityTypesQuery,
  GetAuthorityUserTasksQuery,
  GetAvailableRolesQuery,
  GetCdpAdminUsersQuery,
  GetCommoditiesQuery,
  GetCopiableDiscloserListsQuery,
  GetCycleSamplesByAuthorityTypeIdQuery,
  GetDelegatedRequestsQuery,
  GetDiscloserLateRequestsQuery,
  GetDiscloserListByIdentifierQuery,
  GetDiscloserListExportFileLinkQuery,
  GetDiscloserListFilterOptionsQuery,
  GetDiscloserListForCycleQuery,
  GetDiscloserListOrganisationByIdQuery,
  GetDiscloserListTrackingPortfoliosQuery,
  GetDisclosingOrganisationQuery,
  GetDisclosureCycleByIdQuery,
  GetExistingProjectRequestsNumberQuery,
  GetExistingProjectRequestsQuery,
  GetFutureDisclosureCyclesQuery,
  GetImportListReportsQuery,
  GetImportListTemplateQuery,
  GetImportedProjectInstanceOrganisationsQuery,
  GetInviteByJwtQuery,
  GetIsInvestorRequestedByCmsQuery,
  GetMyDetailsQuery,
  GetMyOrgCountryQuery,
  GetMyOrganisationDetailsQuery,
  GetNextDisclosureCycleForDiscloserQuery,
  GetNextDisclosureCycleQuery,
  GetOptionalProjectRequestsQuery,
  GetOrgResponseByCycleQuery,
  GetOrganisationAccountManagerQuery,
  GetOrganisationAuthorityTypesQuery,
  GetOrganisationByIdentifierQuery,
  GetOrganisationFilterOptionsQuery,
  GetOrganisationInvoiceQuery,
  GetOrganisationUsersQuery,
  GetPhasesInformationQuery,
  GetProjectByIdQuery,
  GetProjectInstanceByIdQuery,
  GetProjectInstanceOrganisationsQuery,
  GetProjectInstanceSnapshotQuery,
  GetProjectInstancesByAuthorityQuery,
  GetProjectInstancesQuery,
  GetProjectsQuery,
  GetQuestionnaireSetupByOrgIdQuery,
  GetQuestionnaireSetupQuery,
  GetRequestersQuery,
  GetResponseByOrganisationIdQuery,
  GetResponseRateStatsQuery,
  GetSampleOrganisationsQuery,
  GetSampleQuery,
  GetSampleResponseRateQuery,
  GetSubsidiaryRequestDetailsQuery,
  GetSubsidiaryRequestsQuery,
  GetTagExportFileLinkQuery,
  GetTodosQuery,
  GetTopCountriesForSampleQuery,
  GetTopIndustriesForSampleQuery,
  GetTrackingPorfolioByIdQuery,
  GetTrackingPortfolioExportFileLinkQuery,
  IsSampleCdpAuthorityQuery,
  JoinOptionalProjectMutation,
  MyRequestsQuery,
  PublishProjectInstanceMutation,
  PublishSampleMutation,
  QuestionnaireSetupPathway,
  RemoveDiscloserFromListMutation,
  RemoveOrganisationsFromProjectInstanceMutation,
  SearchCountryQuery,
  SearchOrganisationQuery,
  SetResponseMappingMutation,
  SubmitDiscloserListMutation,
  TopCountriesQuery,
  TopIndustriesQuery,
  UnpublishProjectInstanceMutation,
  UnpublishSampleMutation,
  UpdateActivityRevenuePercentagesMutation,
  UpdateAuthorityReferenceMutation,
  UpdateDiscloserEmailMutation,
  UpdateDiscloserListOrganisationThemesMutation,
  UpdateMyLanguagePreferencesMutation,
  UpdateOrganisationDetailsMutation,
  UpdateOrganisationDisclosureCommoditiesMutation,
  UpdateProjectMutation,
  UpdateQuestionnaireSetupMutation,
  UpdateResponseExtensionMutation,
  UpdateSampleMutation,
  UpdateUserDetailsMutation,
} from '@/lib/discloser/__generated__/graphql';
import { AuthorityTypeIdService } from '@/types/authority.type.id.service';
import { ResponseStatusEnum } from '@/types/response';
import { discloserConfig } from '@config';

const discloserServer = graphql.link(discloserConfig.graphql);

const getUserTasks: GetAuthorityUserTasksQuery = {
  getAuthorityUserTasks: {
    createActiveCycleList: true,
    createNextCycleList: false,
  },
};

export const getActiveDisclosureCycleResponse: GetActiveDisclosureCycleQuery = {
  getActiveDisclosureCycle: {
    startDate: '2023-01-01T12:45:50.311Z',
    endDate: '2023-12-30T12:45:50.311Z',
    disclosureCycleId: '34cd9b53-1cce-4c40-9edd-1f41fb86d59d',
    deadlineToDisclose: '2021-11-01T12:45:50.311Z',
    year: 2023,
    submitDueDate: '2023-11-01T12:45:50.311Z',
    name: 'Disclosure Cycle for 2023',
  },
};

export const getActiveDisclosureCycleForDiscloserResponse: GetActiveDisclosureCycleForDiscloserQuery = {
  getActiveDisclosureCycle: {
    startDate: '2023-01-01T12:45:50.311Z',
    endDate: '2023-12-30T12:45:50.311Z',
    disclosureCycleId: '34cd9b53-1cce-4c40-9edd-1f41fb86d59d',
    year: 2023,
    submitDueDate: '2023-11-01T12:45:50.311Z',
    name: 'Disclosure Cycle for 2023',
  },
};

export const getDiscloserListForCycleResponse: GetDiscloserListForCycleQuery = {
  getDiscloserListForCycle: {
    discloserListId: 'd523e4fd-4c0b-4bd6-82e3-9a12f0b2f0f8',
    title: 'Disclosure Cycle for 2023 Supply Chain Authority Discloser List',
    status: 'pending',
    totalOrganisationCount: 2987,
    invitedOrganisationsCount: 2987,
    submittable: false,
    isBeforeBusinessDeadline: true,
    scoringDeadline: '2021-12-01T12:45:50.311Z',
    deadlineToDisclose: '2021-11-01T12:45:50.311Z',
    submitDueDate: '2024-10-01T11:45:50.311Z',
    disclosureCycle: {
      year: 2023,
      name: 'Disclosure Cycle for 2023',
      disclosureCycleId: '34cd9b53-1cce-4c40-9edd-1f41fb86d59d',
      startDate: '2023-01-01T12:45:50.311Z',
      endDate: '2023-12-30T12:45:50.311Z',
    },
    authorityType: {
      authorityTypeId: '0b-4bd6-82e3-9a12f0b0b-4bd6-82e3-9a12f0b',
    },
    maxNumberOfDisclosures: 3000,
  },
};

const getTopIndustriesResponse: TopIndustriesQuery = {
  topIndustries: [
    {
      industryId: 'f013f62b-f6d7-4773-a204-f7a0f3ffa46a',
      industryName: 'Services',
      count: 2,
      percentage: 25,
    },
    {
      industryId: '0c9d38bb-33cd-473a-ac5c-9339c37f3603',
      industryName: 'Power generation',
      count: 1,
      percentage: 12.5,
    },
    {
      industryId: '25acdcba-cfa9-4fb9-8a46-918bebd43e51',
      industryName: 'Transportation services',
      count: 1,
      percentage: 12.5,
    },
    {
      industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
      industryName: 'Apparel',
      count: 1,
      percentage: 12.5,
    },
    {
      industryId: '6f6be648-7ed9-4884-a557-9d1a4c79b59c',
      industryName: 'Food, beverage & agriculture',
      count: 1,
      percentage: 12.5,
    },
    {
      industryId: null,
      industryName: 'Other',
      count: 2,
      percentage: 25,
    },
  ],
};

const getMyOrgCountryResponse: GetMyOrgCountryQuery = {
  getMyOrganisationDetails: {
    OrgCountry: {
      countryId: '12345',
      countryName: 'Micronesia',
    },
  },
};

const getTopCountriesResponse: TopCountriesQuery = {
  topCountries: [
    {
      percentage: 50,
      countryName: 'Japan',
      count: 4,
      countryId: '459a3c8d-a319-4bbc-a797-94200d2a6571',
      countryAlpha2: 'JP',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 25,
      countryName: 'Brazil',
      count: 2,
      countryId: '1fa03075-4979-4b4f-a0a0-4efaf52b7c51',
      countryAlpha2: 'BR',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 13,
      countryName: 'Germany',
      count: 1,
      countryId: '7464f9fc-466c-4612-a3c1-cab52befc1c8',
      countryAlpha2: 'DE',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 13,
      countryName: 'United States of America',
      count: 1,
      countryId: 'c10c2dec-737b-4f1c-a450-934471f95a9b',
      countryAlpha2: 'US',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 0,
      countryName: 'Other',
      count: 0,
      countryId: null,
      countryAlpha2: null,
      __typename: 'TopCountriesModel',
    },
  ],
};

const top5CountriesResponse: TopCountriesQuery = {
  topCountries: [
    {
      percentage: 50,
      countryName: 'Japan',
      count: 4,
      countryId: '459a3c8d-a319-4bbc-a797-94200d2a6571',
      countryAlpha2: 'JP',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 25,
      countryName: 'Brazil',
      count: 2,
      countryId: '1fa03075-4979-4b4f-a0a0-4efaf52b7c51',
      countryAlpha2: 'BR',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 13,
      countryName: 'Germany',
      count: 1,
      countryId: '7464f9fc-466c-4612-a3c1-cab52befc1c8',
      countryAlpha2: 'DE',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 13,
      countryName: 'United States of America',
      count: 1,
      countryId: 'c10c2dec-737b-4f1c-a450-934471f95a9b',
      countryAlpha2: 'US',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 0,
      countryName: 'Other',
      count: 0,
      countryId: null,
      countryAlpha2: null,
      __typename: 'TopCountriesModel',
    },
  ],
};

export const getSampleResponseRateResponse: GetSampleResponseRateQuery = {
  getSampleResponseRate: [
    {
      status: 'No Response',
      count: 15,
      percentage: 100,
      __typename: 'SampleDonutResponseRateModel',
    },
    {
      status: 'Activated',
      count: 0,
      percentage: 0,
      __typename: 'SampleDonutResponseRateModel',
    },
    {
      status: 'Submitted',
      count: 0,
      percentage: 0,
      __typename: 'SampleDonutResponseRateModel',
    },
    {
      status: 'Amended',
      count: 0,
      percentage: 0,
      __typename: 'SampleDonutResponseRateModel',
    },
    {
      status: 'Request Not Sent',
      count: 0,
      percentage: 0,
      __typename: 'SampleDonutResponseRateModel',
    },
  ],
};

const getTopCountriesForSampleResponse: GetTopCountriesForSampleQuery = {
  getTopCountriesForSample: [
    {
      percentage: 20,
      count: 2,
      countryAlpha2: 'AU',
      countryName: 'Australia',
      countryId: '1106b006-4a63-ec11-8f8f-000d3a649a74',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 20,
      count: 2,
      countryAlpha2: 'BR',
      countryName: 'Brazil',
      countryId: '1206b006-4a63-ec11-8f8f-000d3a649a74',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 20,
      count: 2,
      countryAlpha2: 'DE',
      countryName: 'Germany',
      countryId: '1906b006-4a63-ec11-8f8f-000d3a649a74',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 20,
      count: 2,
      countryAlpha2: 'JP',
      countryName: 'Japan',
      countryId: '1f06b006-4a63-ec11-8f8f-000d3a649a74',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 10,
      count: 1,
      countryAlpha2: 'IT',
      countryName: 'Italy',
      countryId: '2006b006-4a63-ec11-8f8f-000d3a649a74',
      __typename: 'TopCountriesModel',
    },
    {
      percentage: 10,
      count: 1,
      countryAlpha2: 'Other',
      countryName: 'Other',
      countryId: 'Other',
      __typename: 'TopCountriesModel',
    },
  ],
};

const getIsRequestedByCmsResponse: GetIsInvestorRequestedByCmsQuery = {
  getMyOrganisationDetails: {
    isInvestorRequested: true,
    isRequestedByCDPCapitalMarkets: true,
  },
};

const getTopIndustriesForSampleResponse: GetTopIndustriesForSampleQuery = {
  getTopIndustriesForSample: [
    {
      industryId: '279603e9-338f-4c59-b7ba-22ff52e647a9',
      industryName: 'Food, beverage & agriculture',
      count: 2,
      percentage: 20,
      __typename: 'TopIndustriesModel',
    },
    {
      industryId: '4ca539f6-a729-4200-9565-291af1ca4243',
      industryName: 'Retail',
      count: 2,
      percentage: 20,
      __typename: 'TopIndustriesModel',
    },
    {
      industryId: '582f3854-273f-4a14-b4f9-92b4e2d5ba3b',
      industryName: 'Biotech, health care & pharma',
      count: 2,
      percentage: 20,
      __typename: 'TopIndustriesModel',
    },
    {
      industryId: 'a2862963-3797-4c52-afec-47b9992b74cc',
      industryName: 'Power generation',
      count: 2,
      percentage: 20,
      __typename: 'TopIndustriesModel',
    },
    {
      industryId: 'ac424d99-1c08-4adb-95ce-c4a35fb54ccb',
      industryName: 'Infrastructure',
      count: 1,
      percentage: 10,
      __typename: 'TopIndustriesModel',
    },
    {
      industryId: 'Other',
      industryName: 'Other',
      count: 1,
      percentage: 10,
      __typename: 'TopIndustriesModel',
    },
  ],
};

export const getOrganisationResponse: GetOrganisationByIdentifierQuery = {
  getOrganisationByIdentifier: {
    organisationId: 'c3dcba63-208f-4494-b2a6-210901a74234',
    orgName: 'Romaguera - Schmeler',
    activity: {
      activityName: 'channels',
    },
    activityGroup: {
      activityGroupName: 'paradigms',
    },
    industry: {
      industryName: 'schemas',
    },
    OrgHqCountry: {
      countryName: "Cote d'Ivoire",
    },
    OrgCountry: {
      countryName: 'Micronesia',
    },
    ParentOrganisation: {
      orgName: 'Parent Org',
    },
    BIC: 'functionalities',
    Ticker: 'convergence',
    ISIN: 'guLGWrCyrK',
    LEI: 'TKUFhLLfqH',
    DUNS: 'UjmzJamXQF',
  },
};

export const getAllDiscloserListsResponse: GetAllAuthorityDiscloserListsQuery = {
  getAllAuthorityDiscloserLists: [
    {
      discloserListId: 'f8767b07-a7cc-4b98-a60f-459b1be33536',
      title: 'Discloser List for Disclosure Cycle for 2024',
      status: 'active',
      blocked: false,
      created_on: '2023-06-19T13:54:40.387Z',
      updated_on: '2023-06-19T13:54:40.387Z',
      responseRate: 25,
      activeDates: null,
      disclosureCycle: {
        name: 'Disclosure Cycle for 2022',
        disclosureCycleId: '606373d1-3295-48f9-abc9-0f734d1e82f8',
        year: 2022,
        startDate: '2023-01-01T12:45:50.311Z',
        endDate: '2023-12-30T12:45:50.311Z',
        submitDueDate: '2022-10-01T11:45:50.311Z',
        deadlineToDisclose: '2021-11-01T12:45:50311Z',
      },
      invitedOrganisationsCount: 1,
      totalOrganisationCount: 1,
    },
    {
      discloserListId: '0897aa34-cb5d-414c-a954-3c49c2523b24',
      title: 'Discloser List for undefined',
      status: 'pending',
      blocked: false,
      created_on: '2022-06-09T14:00:33.819Z',
      updated_on: '2022-06-19T13:54:40.387Z',
      responseRate: 40,
      disclosureCycle: {
        name: 'Disclosure Cycle for 2021',
        disclosureCycleId: 'f7ce849f-e026-4018-9ae5-79933a2fe958',
        year: 2021,
        startDate: '2022-01-01T12:45:50.311Z',
        endDate: '2022-12-30T12:45:50.311Z',
        submitDueDate: '2021-10-01T11:45:50.311Z',
        deadlineToDisclose: '2021-11-01T12:45:50.311Z',
      },
      activeDates: {
        startDate: '2022-03-01T12:45:50.311Z',
        endDate: '2022-12-30T12:45:50.311Z',
      },
      invitedOrganisationsCount: 1,
      totalOrganisationCount: 1,
    },
    {
      discloserListId: '0e10265a-1a1f-4174-a224-aa0d50d7073b',
      title: 'Discloser List for undefined',
      status: 'closed',
      blocked: false,
      created_on: '2021-06-09T13:16:18.458Z',
      updated_on: '2021-06-19T13:54:40.387Z',
      responseRate: 100,
      disclosureCycle: {
        name: 'Disclosure Cycle for 2020',
        disclosureCycleId: 'f70101d8-b390-4eb8-8bbe-48c5ccdd327d',
        year: 2020,
        startDate: '2021-01-01T12:45:50.311Z',
        endDate: '2021-12-30T12:45:50.311Z',
        submitDueDate: '2020-10-01T11:45:50.311Z',
        deadlineToDisclose: '2021-11-01T12:45:50.311Z',
      },
      activeDates: {
        startDate: '2021-03-01T12:45:50.311Z',
        endDate: '2021-12-30T12:45:50.311Z',
      },
      invitedOrganisationsCount: 1,
      totalOrganisationCount: 1,
    },
  ],
};

export const getDiscloserResponse: GetDiscloserListByIdentifierQuery = {
  getDiscloserListByIdentifier: {
    authorityOrg: {
      canAddContacts: true,
    },
    authorityType: {
      name: 'Supply Chain',
      authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
    },
    maxNumberOfDisclosures: 30,
    totalIncompleteDiscloserListOrganisations: 0,
    title: 'Discloser List for Disclosure Cycle for 2024',
    discloserListId: '158a41cd-0013-47dc-ab6c-37d7cbc41867',
    updated_on: '2023-06-19T13:54:40.387Z',
    disclosureCycleId: '59945519-6300-4397-9884-0c7455b2d8fd',
    status: 'active',
    blocked: false,
    isBeforeBusinessDeadline: true,
    scoringDeadline: '2021-12-01T12:45:50.311Z',
    submitted: true,
    submittable: true,
    isAmendable: false,
    mustBeSubmitted: true,
    totalOrganisationCount: 1,
    totalSubmittedOrgToBeAdded: 0,
    latestAdditionsCount: 1,
    discloserListOrganisations: {
      counts: {
        all: 1,
        noResponse: 1,
        activated: 0,
        submitted: 0,
      },
      data: [
        {
          discloserListOrganisationId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
          status: 'requested',
          response: {
            responseStatus: 'No Response',
            progress: 85,
            confirmIntention: true,
          },
          previousResponse: {
            responseStatus: 'No Response',
            created_on: '',
          },
          forestTheme: false,
          waterTheme: true,
          requestsCount: 2,
          organisation: {
            isInvestorRequested: true,
            organisationNo: 3,
            industry: {
              industryName: 'schemas',
            },
            orgName: 'Rolfson Inc',
            organisationId: 'be528eb9-c80f-489f-9107-85f566fb3eda',
            OrgCountry: {
              countryName: 'Saint Kitts and Nevis',
            },
            DUNS: 'e-business',
            masterId: null,
          },
          disclosingOrganisation: {
            orgName: 'Disclosing org',
          },
        },
      ],
    },
  },
};

export const getDicloserListLateRequestsResponse: GetDiscloserLateRequestsQuery = {
  getDiscloserListLateRequestForCycle: {
    totalRequests: 2,
    requests: [
      {
        responseStatus: '',
        discloserListOrganisationLateRequestId: 'unique-id-2',
        organisationId: '831428fa-2d35-400f-8d8f-df4042993531',
        created_on: '2024-06-12T16:19:39.963Z',
        waterTheme: true,
        forestTheme: true,
        climateTheme: true,
        organisation: {
          orgName: '888 Holdings',
          organisationNo: 30,
          DUNS: '100000005',
          organisationId: '831428fa-2d35-400f-8d8f-df4042993531', // Added organisationId
          __typename: 'OrganisationModel',
        },
        requestingAuthority: {
          organisationType: {
            name: 'Some Authority Name',
          },
          orgName: 'Some Authority Name',
          __typename: 'OrganisationModel',
        },
        __typename: 'DiscloserListOrganisationLateRequest',
      },
      {
        discloserListOrganisationLateRequestId: 'unique-id-2',
        responseStatus: '',
        organisationId: 'b519e07e-b7c2-415a-a3ca-89e38bffedb3',
        created_on: '2024-06-12T16:19:37.186Z',
        waterTheme: false,
        forestTheme: true,
        climateTheme: true,
        organisation: {
          organisationId: 'b519e07e-b7c2-415a-a3ca-89e38bffedb3',
          orgName: '3M Company',
          organisationNo: 285,
          DUNS: '100000003',
          __typename: 'OrganisationModel',
        },
        requestingAuthority: {
          organisationType: {
            name: 'Some Authority Name',
          },
          orgName: 'Some Authority Name',
          __typename: 'OrganisationModel',
        },
        __typename: 'DiscloserListOrganisationLateRequest',
      },
    ],
  },
};

export const getDiscloserListOrganisationByIdResponse: GetDiscloserListOrganisationByIdQuery = {
  getDiscloserListOrganisationById: {
    discloserListOrganisationId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
    status: 'requested',
    discloserEmail: 'test@g.ce',
    authorityReference: '125',
    response: {
      responseStatus: 'No Response',
      progress: 85,
    },
    forestTheme: false,
    waterTheme: true,
    requestsCount: 2,
    organisation: {
      marketCapitalisation: 0,
      isInvestorRequested: true,
      organisationNo: 3,
      localOrgName: 'Rolfson Inc',
      latitude: '0',
      longitude: '0',
      activity: {
        activityName: 'channels',
      },
      activityGroup: {
        activityGroupName: 'paradigms',
      },
      industry: {
        industryName: 'schemas',
      },
      orgName: 'Rolfson Inc',
      organisationId: 'be528eb9-c80f-489f-9107-85f566fb3eda',
      ParentOrganisation: {
        orgName: 'Parent Org',
      },
      stateOrRegion: 'New York',
      OrgCountry: {
        countryName: 'Saint Kitts and Nevis',
      },
      OrgHqCountry: {
        countryName: "Cote d'Ivoire",
      },
      organisationType: {
        name: 'Company',
      },
      BIC: 'functionalities',
      Ticker: 'convergence',
      LEI: 'web services',
      ISIN: 'ROI',
      DUNS: 'e-business',
    },
    disclosingOrganisation: {
      orgName: 'Disclosing org',
    },
  },
};

const getDiscloserListFilterOptionsResponse: GetDiscloserListFilterOptionsQuery = {
  getDiscloserListFilterOptions: {
    industries: [
      {
        industryId: '14769ac4-7970-4846-971c-d411168b310f',
        industryName: 'Biotech, health care & pharma',
        __typename: 'IndustryModel',
      },
    ],
    countries: [
      {
        countryId: '1fa03075-4979-4b4f-a0a0-4efaf52b7c51',
        countryName: 'Brazil',
        __typename: 'CountryModel',
      },
    ],
    status: [
      'new',
    ],
    lastDisclosed: null,
    __typename: 'FilterOptionsModel',
  },
};

const getSearchOrganisationResponse: SearchOrganisationQuery = {
  searchOrganisation: {
    totalCount: 2,
    organisations: [
      {
        organisationId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
        organisationAuthorityTypes: [],
        orgName: 'HOKURIKU ELECTRIC INDUSTRY CO.,LTD.',
        organisationNo: 837493,
        DUNS: 'DUNS_866141',
        LEI: 'LEI_866141',
        responseStatus: 'resonse-status',
        industry: {
          industryName: 'architectures',
        },
        OrgCountry: {
          countryName: 'Japan',
        },
        ParentOrganisation: {
          orgName: 'HOKURIKU!',
        },
        organisationType: {
          name: 'corporate',
        },
      },
      {
        organisationId: '0a7d49fa-9993-40d7-800d-e7baf40d0c0d',
        organisationAuthorityTypes: [
          {
            authorityType: {
              name: 'Capital Markets Signatory',
            },
          },
        ],
        orgName: 'CHENG SHIN RUBBER INDUSTRY CO., LTD',
        organisationNo: 848461,
        DUNS: 'DUNS_72840',
        LEI: 'LEI_72840',
        ParentOrganisation: null,
        industry: {
          industryName: 'paradigms',
        },
        OrgCountry: {
          countryName: 'Taiwan, China',
        },
        organisationType: {
          name: 'city',
        },
      },
    ],
  },
};

const getOrganisationFilterOptionsResponse: GetOrganisationFilterOptionsQuery = {
  getOrganisationFilterOptions: {
    industries: [
      {
        industryId: 'architecturesIndustryId',
        industryName: 'architectures',
        __typename: 'IndustryModel',
      },
    ],
    authTypes: null,
    orgTypes: null,
    countries: null,
    idTypes: [
      'LEI',
      'DUNS',
      'Ticker',
      'BIC',
      'ISIN',
    ],
    __typename: 'OrganisationFilterOptionsTypeModel',
  },
};

const addDiscloserResponse: AddToDiscloserListMutation = {
  addToDiscloserList: {
    success: true,
  },
};

const createDiscloserListInputResponse: CreateDiscloserListMutation = {
  createDiscloserList: {
    discloserListId: '59945519-6300-4397-9884-0c7455b2d8fd',
  },
};

const removeDiscloserResponse: RemoveDiscloserFromListMutation = {
  removeDiscloserFromList: {
    success: true,
  },
};

const submitDiscloserResponse: SubmitDiscloserListMutation = {
  submitDiscloserList: {
    submittedOn: '2023-06-21T09:06:46.325Z',
  },
};

const getCopiableDiscloserListsResponse: GetCopiableDiscloserListsQuery = {
  getCopiableDiscloserLists: [
    {
      discloserListId: '4688587b-d35a-42b4-bade-24b2afd0ea59',
      title: 'Disclosure Cycle for 2022 Supply Chain Frontend Demo Authority Discloser List',
    },
    {
      discloserListId: '436ca2ab-fd9b-4a14-8256-9ca9ecbfdcc4',
      title: 'Disclosure Cycle for 2021 Supply Chain Frontend Demo Authority Discloser List',
    },
    {
      discloserListId: '66b471d6-8e38-473d-89a0-33f0f78b07b0',
      title: 'Disclosure Cycle for 2020 Supply Chain Frontend Demo Authority Discloser List',
    },
  ],
};

const searchCountryResponse: SearchCountryQuery = {
  searchCountry: [
    {
      countryName: 'Jamaica',
      countryId: '188317e4-b2a5-4bdb-90ff-c4ae1a34b2ba',
      alpha2: 'MA',
    },
    {
      countryName: 'Japan',
      countryId: '459a3c8d-a319-4bbc-a797-94200d2a6571',
      alpha2: 'MA',
    },
    {
      countryName: 'Jersey',
      countryId: '00ccab89-3a8d-4830-9781-a990085cfae6',
      alpha2: 'MA',
    },
    {
      countryName: 'Jordan',
      countryId: 'a3321bf0-a80f-4ea2-a1b5-89ad3c6a80d2',
      alpha2: 'MA',
    },
  ],
};

const getAllIndustriesResponse: GetAllIndustriesQuery = {
  getAllIndustries: [
    {
      industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
      industryName: 'Apparel',
      activityGroups: [
        {
          industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
          activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
          activityGroupName: 'Apparel design',
          activities: [
            {
              activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
              activityName: 'Apparel & footwear design',
              activityGroup: {
                activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
              },
              questionnaireSector: {
                questionnaireSectorId: '9783f1f8-fa21-4455-a599-13da2868b8dd',
                name: 'General',
              },
            },
          ],
        },
      ],
    },
    {
      industryId: '14769ac4-7970-4846-971c-d411168b310f',
      industryName: 'Biotech, health care & pharma',
      activityGroups: [
        {
          industryId: '14769ac4-7970-4846-971c-d411168b310f',
          activityGroupId: '0ca5499f-5d45-47e0-a6dd-8652a76ae294',
          activityGroupName: 'Textiles & fabric goods',
          activities: [
            {
              activityId: '134f4765-b779-4d38-b753-d8096acaa82f',
              activityName: 'Apparel & footwear',
              activityGroup: {
                activityGroupId: '0ca5499f-5d45-47e0-a6dd-8652a76ae294',
              },
              questionnaireSector: {
                questionnaireSectorId: '9783f1f8-fa21-4455-a599-13da2868b8dd',
                name: 'General',
              },
            },
          ],
        },
      ],
    },
    {
      industryId: '6f6be648-7ed9-4884-a557-9d1a4c79b59c',
      industryName: 'Food, beverage & agriculture',
      activityGroups: [
        {
          industryId: '6f6be648-7ed9-4884-a557-9d1a4c79b59c',
          activityGroupId: 'f2a236bd-c739-42c0-90ed-155f7afd2fa8',
          activityGroupName: 'Textiles & fabric goods',
          activities: [
            {
              activityId: '2cc1c2dd-77b7-4755-a2bb-6e35cec0d513',
              activityName: 'Luggage & bags',
              activityGroup: {
                activityGroupId: 'f2a236bd-c739-42c0-90ed-155f7afd2fa8',
              },
              questionnaireSector: {
                questionnaireSectorId: '9783f1f8-fa21-4455-a599-13da2868b8dd',
                name: 'General',
              },
            },
          ],
        },
      ],
    },
  ],
};

const checkIfOrgsInTheListResponse: CheckIfOrgsInTheListQuery = {
  checkIfOrgsInTheList: [
    //'HOKURIKU ELECTRIC INDUSTRY CO.,LTD.'
    {
      organisationId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
      result: false,
      __typename: 'CheckIfOrgsInTheListModel',
    },
    //'CHENG SHIN RUBBER INDUSTRY CO., LTD'
    {
      organisationId: '0a7d49fa-9993-40d7-800d-e7baf40d0c0d',
      result: true,
      __typename: 'CheckIfOrgsInTheListModel',
    },
  ],
};

const getImportListReportsResponse: GetImportListReportsQuery = {
  getImportListReports: [
    {
      created_on: '2023-08-14T16:09:29.940Z',
      reportBlobLink: null,
      status: 'Successful',
      updatedOrgsCount: 0,
      errorsCount: 0,
      warningsCount: 2,
      createdOrgsCount: 8,
      deletedOrgsCount: 2,
      importedOrgsCount: 8,
      fileError: null,
    },
    {
      created_on: '2023-10-13T16:09:29.940Z',
      status: 'Invalid File Format',
      updatedOrgsCount: 0,
      errorsCount: 0,
      warningsCount: 0,
      createdOrgsCount: 0,
      deletedOrgsCount: 0,
      importedOrgsCount: 0,
      fileError: 'Wrong File Format',
      reportBlobLink: null,
    },
  ],
};

const getImportListTemplateReponse: GetImportListTemplateQuery = {
  getImportListTemplate: {
    link: 'https://azure_blob_link.com',
  },
};

const updateAuthorityReferenceResponse: UpdateAuthorityReferenceMutation = {
  updateAuthorityReference: {
    warning: false,
    __typename: 'UpdateAuthorityReferenceModelType',
  },
};

const updateDiscloserEmailResponse: UpdateDiscloserEmailMutation = {
  updateDiscloserEmail: {
    success: true,
    __typename: 'UpdateDiscloserEmailModelType',
  },
};

const getDiscloserListExportFileLinkResponse: GetDiscloserListExportFileLinkQuery = {
  getDiscloserListExportFileLink: {
    link: 'https://azure_blob_link.com',
  },
};

const updateDiscloserListThemesResponse: UpdateDiscloserListOrganisationThemesMutation = {
  updateDiscloserListOrganisationThemes: {
    __typename: 'UpdateThemesModelType',
    success: true,
  },
};

export const getMyOrganisationDetailsResponse: GetMyOrganisationDetailsQuery = {
  getMyOrganisationDetails: {
    organisationId: 'c3dcba63-208f-4494-b2a6-210901a74234',
    canAddContacts: true,
    DUNS: 'DUNS_814',
    isEligibleToPay: true,
    LEI: 'LEI_814',
    orgName: 'Rolfson Inc',
    localOrgName: 'Rolfson Inc',
    description: 'This is the organisations description',
    website: 'www.org-details.com',
    logoUrl: 'https://azure_blob_link.com',
    orgHqAddressLine1: '80 Charlotte Street',
    isRequestedByCDPCapitalMarkets: true,
    orgTypeId: 'eec2e5f5-802a-e711-a94c-0050569976b2',
    orgHqPostalCode: 'W1T 4QS',
    orgHqCity: 'London',
    OrgHqCountry: {
      countryName: 'Japan',
      countryId: '459a3c8d-a319-4bbc-a797-94200d2a6571',
    },
    activity: {
      activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
      activityName: 'Apparel & footwear design',
    },
    activityGroup: {
      activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
      activityGroupName: 'Apparel design',
    },
    industry: {
      industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
      industryName: 'Apparel',
    },
    organisationAuthorityTypes: [
      {
        authorityType: {
          authorityTypeId: AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY(),
          name: 'Capital Markets Signatory',
        },
      },
      {
        authorityType: {
          authorityTypeId: '123',
          name: 'Supply Chain',
        },
      },
    ],
    isC40Requested: false,
    hasNonPublicAuthoritiesRequests: false,
    organisationType: {
      organisationTypeId: 'f18d927d-9083-ee11-8179-6045bd905d39',
      name: 'city',
    },
    organisationTags: [
      {
        tag: {
          name: 'Construction',
          __typename: 'TagModel',
        },
        __typename: 'OrganisationTagModel',
      },
      {
        tag: {
          name: 'General',
          __typename: 'TagModel',
        },
        __typename: 'OrganisationTagModel',
      },
    ],
    organisationActivityClassifications: [
      {
        organisationActivityClassificationId: '85d92321-5284-4ea0-a632-21c96e4cab6a',
        industry: {
          industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
          industryName: 'Infrastructure',
          __typename: 'IndustryModel',
        },
        activityGroup: {
          activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
          activityGroupName: 'Construction',
          __typename: 'ActivityGroupModel',
        },
        activity: {
          activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
          activityName: 'Non-residential building construction',
          questionnaireSector: {
            name: 'Construction',
            __typename: 'QuestionnaireSectorModel',
          },
          __typename: 'ActivityModel',
        },
        sortOrder: null,
        isPrimary: true,
        industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
        activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
        activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
        __typename: 'OrganisationActivityClassificationModel',
      },
    ],
    isInvestorRequested: false,
    isPublicAuthority: false,
    partners: [],
    firstAddPartnerUserId: null,
    submissionLead: null,
  },
};

const updateOrgDetailsResponse: UpdateOrganisationDetailsMutation = {
  updateOrganisationDetails: {
    updated_on: '2023-09-06T18:04:40.608Z',
    description: null,
    website: 'www.test.com',
    orgHqPostalCode: null,
    orgHqAddressLine1: 'BCG, 80 Charlotte Street',
    orgHqCity: null,
    OrgHqCountry: {
      countryId: 'ad136b67-7929-4103-b329-a63930c68b13',
      countryName: 'United Kingdom of Great Britain and Northern Ireland',
    },
    activity: {
      activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
      activityName: 'Apparel & footwear design',
    },
    activityGroup: {
      activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
      activityGroupName: 'Apparel design',
    },
  },
};

export const getMyDetailsResponse: GetMyDetailsQuery = {
  getMyDetails: {
    id: 'dc3d25a3-7732-4f98-a99d-06222fedc2e2',
    jobTitle: 'PPL',
    givenName: 'bilgehan',
    surname: 'yildirim',
    emailAddress: 'titan_1@cdp.com',
    organisation: {
      orgName: 'Frontend Demo Authority',
      orgTypeId: 'f18d927d-9083-ee11-8179-6045bd905d39',
      __typename: 'OrganisationModel',
    },
  },
};

const checkIfCanBeScoredResponse: CheckIfCanBeScoredQuery = {
  checkIfCanBeScored: true,
};

const getResponseRateStatsResponse: GetResponseRateStatsQuery = {
  getResponseRateStats: [
    {
      status: 'NOT_STARTED',
      authorityOrgsCount: 0,
      totalOrgsCount: 8,
      authorityOrgsPercentage: 0,
      totalOrgsPercentage: 0,
      __typename: 'ResponseRateModel',
    },
    {
      status: 'IN_PROGRESS',
      authorityOrgsCount: 0,
      totalOrgsCount: 8,
      authorityOrgsPercentage: 0,
      totalOrgsPercentage: 0,
      __typename: 'ResponseRateModel',
    },
    {
      status: 'SUBMITTED',
      authorityOrgsCount: 0,
      totalOrgsCount: 8,
      authorityOrgsPercentage: 0,
      totalOrgsPercentage: 0,
      __typename: 'ResponseRateModel',
    },
  ],
};

export const MyRequestsResponse: MyRequestsQuery = {
  myRequests: {
    totalRequesters: 2,
    requests: [
      {
        created_on: '2023-10-15T13:30:00.000Z',
        requestedOrganisation: {
          organisationId: 'e9c3205d-3db6-4b4b-8942-6fcc3f8475a5',
          orgName: 'Some Requested Org Name',
        },
        organisation: {
          organisationId: 'e9c3205d-3db6-4b4b-8942-6fcc3f8475a5',
          orgName: 'Some Org Name',
        },
        disclosingOrganisation: {
          organisationId: '00016e63-4645-4a20-80a2-3991452565d4',
          orgName: 'Some Disclosing Org Name',
        },
        requestingAuthorityType: {
          authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
          name: 'Supply Chain',
        },
        requestingAuthorityId: '1eb37cbd-b380-44cd-9a09-d8407db503cb',
        requestingAuthority: {
          organisationId: '1eb37cbd-b380-44cd-9a09-d8407db503cb',
          orgName: 'Raizen S.A.',
          logoUrl: 'https://azure_blob_link.com',
          contactName: 'John Doe',
          contactEmail: 'john.doe@raizensa.com',
          organisationAuthorityTypes: [
            {
              authorityType: {
                authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
                name: 'Supply Chain',
              },
              authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
            },
          ],
        },
        forestThemeRequsted: false,
        climateThemeRequested: true,
        waterThemeRequested: false,
        response: {
          responseStatus: 'Activated',
          progress: 85,
        },
        discloserList: {
          submittedOn: '2023-10-15T12:24:07.383Z',
        },
      },
      {
        created_on: '2023-10-15T12:24:07.383Z',
        requestedOrganisation: {
          organisationId: 'requestedOrgId',
          orgName: 'Test Org Name',
        },
        organisation: {
          organisationId: 'organisationId',
          orgName: 'Test Org Name',
        },
        disclosingOrganisation: {
          organisationId: 'disclosingOrgId',
          orgName: 'Test Org Name',
        },
        requestingAuthorityType: {
          authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
          name: 'Supply Chain',
        },
        requestingAuthorityId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
        requestingAuthority: {
          organisationId: '00ce1c19-0796-4c96-b07f-6f6aa3e3e2d8',
          orgName: 'HOKURIKU ELECTRIC INDUSTRY CO.,LTD.',
          logoUrl: 'https://azure_blob_link.com',
          contactName: 'Jane Smith',
          contactEmail: 'jane.smith@hokuriku.com',
          organisationAuthorityTypes: [
            {
              authorityType: {
                authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
                name: 'Supply Chain',
              },
              authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
            },
          ],
        },
        forestThemeRequsted: true,
        climateThemeRequested: false,
        waterThemeRequested: true,
        response: {
          responseStatus: 'Activated',
          progress: 85,
        },
        discloserList: {
          submittedOn: '2023-10-15T12:24:07.383Z',
        },
      },
    ],
  },
};

export const getDelegatedRequestsResponse: GetDelegatedRequestsQuery = {
  getDelegatedRequests: {
    totalRequests: 1,
    requests: [
      {
        created_on: '2023-10-15T13:30:00.000Z',
        organisation: {
          orgName: 'Some Org Name',
          logoUrl: 'https://azure_blob_link.com',
        },
        requestingAuthorityType: {
          name: 'Supply Chain',
        },
        requestingAuthority: {
          orgName: 'Raizen S.A.',
          logoUrl: 'https://azure_blob_link.com',
        },
        forestTheme: true,
        climateTheme: true,
        waterTheme: true,
      },
    ],
  },
};
export const mySubsidiaryRequestsResponse: GetSubsidiaryRequestsQuery = {
  mySubsidiaryRequests: {
    requests: [],
    totalRequesters: 0,
  },
};

export const getSubsidiaryRequestDetailsResponse: GetSubsidiaryRequestDetailsQuery = {
  getSubsidiaryRequestDetails: {
    organisationId: 'organisationId',
    climateThemeRequested: true,
    forestThemeRequested: true,
    waterThemeRequested: true,
    isInvestorRequested: true,
    numberOfRequests: 1,
    responseInProgressCount: 1,
    requestorsAuthorityTypes: [
      {
        name: 'Supply chain',
      },
    ],
  },
};

const GetAllTagCollectionsRespose: GetAllTagCollectionsQuery = {
  getAllTagCollections: [
    {
      __typename: 'TagCollectionModel',
      name: 'System Managed Tags',
      tagGroups: [
        {
          __typename: 'TagGroupModel',
          name: 'City Projects and Initiatives',
          tags: [
            {
              __typename: 'TagModel',
              name: '100% Renewable Energy Campaign',
              isVisible: false,
              isTagMatching: true,
              created_on: '2023-10-02T12:58:52.474Z',
              updated_on: '2023-10-02T13:27:26.281Z',
            },
            {
              __typename: 'TagModel',
              name: '2020 Test Project',
              isVisible: false,
              isTagMatching: true,
              created_on: '2023-10-02T12:58:52.515Z',
              updated_on: '2023-10-02T13:27:26.342Z',
            },
          ],
        },
      ],
    },
  ],
};

const getTagExportFileLinkResponse: GetTagExportFileLinkQuery = {
  getTagExportFileLink: {
    link: 'https://azure_blob_link.com',
  },
};

const createTrackingPortfolioResponse: CreateTrackingPortfolioMutation = {
  createTrackingPortfolio: {
    created_on: '2023-10-23T13:54:21.502Z',
    name: 'testTrackingPortfolioName',
    description: 'testTrackingPortfolioDescription',
    __typename: 'TrackingPortfolioModel',
    trackingPortfolioId: '456',
  },
};

const getDiscloserListTrackingPortfoliosResponse: GetDiscloserListTrackingPortfoliosQuery = {
  getDiscloserListTrackingPortfolios: [
    {
      name: 'Test Portfolio Name',
      description: 'Test Portfolio Description',
      trackingPortfolioId: '26056563-4998-4a29-835c-8d34878b0ee3',
      discloserListId: 'testDiscloserListId',
      snapshot: {
        activated: 1,
        noResponse: 1,
        submitted: 0,
        amended: 0,
        requestNotSent: 0,
        total: 2,
        responseRate: 50,
        __typename: 'TrackingPortfolioSnapshotModel',
      },
      __typename: 'TrackingPortfolioModel',
    },
  ],
};

const getTrackingPorfolioByIdResponse: GetTrackingPorfolioByIdQuery = {
  getTrackingPorfolioById: {
    trackingPortfolioId: '1cd5e46c-ce80-4492-a888-c1d5d8e43e9b',
    name: 'Test Portfolio Name',
    description: 'Test Portfolio Description',
    trackingPortfolioOrganisations: {
      count: 1,
      data: [
        {
          discloserListOrganisationId: '0685bef8-322f-4612-a2bd-1f3640f5778e',
          discloserListOrganisation: {
            disclosingOrganisation: {
              orgName: 'Descente Ltd',
              __typename: 'OrganisationModel',
            },
            organisation: {
              orgName: 'Descente Ltd',
              industry: {
                industryName: 'Apparel',
                __typename: 'IndustryModel',
              },
              OrgCountry: {
                countryName: 'Japan',
                __typename: 'CountryModel',
              },
              __typename: 'OrganisationModel',
            },
            status: 'Requested',
            response: {
              responseStatus: 'Submitted',
              progress: 90,
              __typename: 'ResponseModel',
            },
            __typename: 'DiscloserListOrganisationModel',
          },
          __typename: 'TrackingPortfolioOrganisationModel',
        },
      ],
    },
    snapshot: {
      activated: 1,
      noResponse: 1,
      submitted: 1,
      amended: 0,
      requestNotSent: 0,
      total: 3,
      responseRate: 66.66666666666666,
      __typename: 'TrackingPortfolioSnapshotModel',
    },
    __typename: 'TrackingPortfolioModel',
  },
};

const getTrackingPortfolioExportFileLinkResponse: GetTrackingPortfolioExportFileLinkQuery = {
  getTrackingPortfolioExportFileLink: {
    link: 'https://azure_blob_link.com',
  },
};

const deleteTrackingPortfolioResponse: DeleteTrackingPortfolioMutation = {
  deleteTrackingPortfolio: true,
};

const createProjectResponse: CreateProjectMutation = {
  createProject: {
    name: 'testName',
    mainAuthorityId: 'testAuthorityId',
    projectId: 'testProjectId',
    __typename: 'ProjectModel',
  },
};

const getProjectsResponse: GetProjectsQuery = {
  getProjects: {
    totalCount: 1,
    data: [
      {
        __typename: 'ProjectModel',
        projectId: 'testProjectId',
        name: 'testName',
        mainAuthorityId: 'testMainAuthorityId',
        logoUrl: null,
        created_on: '2023-11-07T16:30:00.000Z',
        updated_on: '2023-11-07T16:30:00.000Z',
        authorityOrganisation: {
          orgName: 'Amazon.com Inc',
        },
      },
    ],
  },
};

const getOrganisationUsersResponse: GetOrganisationUsersQuery = {
  getOrganisationUsers: [
    {
      id: 'dc3d25a3-7732-4f98-a99d-06222fedc2e2',
      displayName: 'Bill Yildirim',
      emailAddress: 'titan_1@cdp.com',
      givenName: 'Bill',
      surname: 'Yildirim',
      roles: [
        'authority_admin',
      ],
      __typename: 'UserModel',
    },
  ],
};

const getAvailableRolesResponse: GetAvailableRolesQuery = {
  getAvailableRoles: {
    discloserRoles: [
      {
        name: 'Discloser Submission Lead',
        roleId: 'discloser_submission_lead',
        __typename: 'AvailableRoleModel',
      },
      {
        name: 'Discloser Contributor',
        roleId: 'discloser_contributor',
        __typename: 'AvailableRoleModel',
      },
      {
        name: 'Discloser Admin',
        roleId: 'discloser_admin',
        __typename: 'AvailableRoleModel',
      },
    ],
    authorityRoles: [
      {
        roleId: 'authority_admin',
        name: 'Authority Admin',
        __typename: 'AvailableRoleModel',
      },
      {
        roleId: 'authority_contributor',
        name: 'Authority Contributor',
        __typename: 'AvailableRoleModel',
      },
    ],
    __typename: 'AvailableRolesModel',
  },
};

export const getProjectByIdResponse: GetProjectByIdQuery = {
  getProjectById: {
    created_on: '2023-11-09T16:57:08.254Z',
    updated_on: '2023-11-09T16:57:08.254Z',
    createdBy: '7cb215f8-0064-4556-b4b6-98d66a74ef4a',
    updatedBy: null,
    projectId: 'e6fa2c51-2955-4199-a0e8-d8c3d4cd43b8',
    name: 'Race to Zero',
    authorityOrganisation: {
      orgName: 'Amazon.com Inc',
    },
    mainAuthorityType: { name: 'Supply Chain' },
    description:
      '"Race to Zero" is a global campaign and initiative that aims to mobilize a wide range of stakeholders, including governments, businesses, cities, and individuals, to take meaningful action in the fight against climate change',
    mainAuthorityId: '20f0705c-9924-4408-b427-45e64c1aa087',
    logoUrl: null,
    __typename: 'ProjectModel',
  },
};

const createInvitationResponse: CreateInvitationMutation = {
  createInvitation: {
    email: 'william.nguyen+3@bcg.com',
    __typename: 'InviteModel',
  },
};

const updateUserDetailsResponse: UpdateUserDetailsMutation = {
  updateUserDetails: {
    displayName: 'Will Nguyen',
    jobTitle: null,
    givenName: 'Will',
    surname: 'Nguyen',
    emailAddress: 'titan_1@cdp.com',
    roles: [
      'guest',
    ],
    __typename: 'UserModel',
  },
};

const updateResponseExtensionResponse: UpdateResponseExtensionMutation = {
  updateResponseExtension: {
    responseId: 'e6fa2c51-2955-4199-a0e8-d8c3d4cd43b8',
    extensionReason: 'ods',
    extensionWeeks: 1,
  },
};

const disableUserResponse: DisableUserMutation = {
  disableUser: {
    result: true,
  },
};

const updateProjectResponse: UpdateProjectMutation = {
  updateProject: {
    updated_on: '2023-11-09T19:58:26.637Z',
    updatedBy: '7cb215f8-0064-4556-b4b6-98d66a74ef4a',
    projectId: 'e6fa2c51-2955-4199-a0e8-d8c3d4cd43b8',
    name: 'Race to Zero 2',
    description: 'Project desc',
    mainAuthorityId: '20f0705c-9924-4408-b427-45e64c1aa087',
    logoUrl: null,
    __typename: 'ProjectModel',
  },
};

const deleteProjectResponse: DeleteProjectMutation = {
  deleteProject: {
    created_on: '2023-11-09T22:55:49.445Z',
    updated_on: '2023-11-09T22:55:54.280Z',
    createdBy: '7cb215f8-0064-4556-b4b6-98d66a74ef4a',
    updatedBy: '7cb215f8-0064-4556-b4b6-98d66a74ef4a',
    projectId: 'e6fa2c51-2955-4199-a0e8-d8c3d4cd43b8',
    name: 'Race to Zero',
    description:
      '"Race to Zero" is a global campaign and initiative that aims to mobilize a wide range of stakeholders, including governments, businesses, cities, and individuals, to take meaningful action in the fight against climate change',
    mainAuthorityId: '20f0705c-9924-4408-b427-45e64c1aa087',
    logoUrl: null,
    __typename: 'ProjectModel',
  },
};

const deleteProjectInstanceResponse: DeleteProjectInstanceMutation = {
  deleteProjectInstance: {
    name: 'Race to Zero',
    __typename: 'ProjectInstanceModel',
  },
};

const publishProjectInstanceResponse: PublishProjectInstanceMutation = {
  publishProjectInstance: {
    name: 'Race to Zero',
    __typename: 'ProjectInstanceModel',
  },
};

const unpublishProjectInstanceResponse: UnpublishProjectInstanceMutation = {
  unpublishProjectInstance: {
    name: 'Race to Zero',
    __typename: 'ProjectInstanceModel',
  },
};

export const getInviteByJwtResponse: GetInviteByJwtQuery = {
  getInviteByJwt: {
    __typename: 'InviteModel',
    inviteId: 'fa3c4649-17ff-4cc8-b98e-a59cc81a29e9',
    organisationId: 'e7a71aee-65ac-4c0d-8442-e668c85973b8',
    email: 'billbill@example.com',
    domain: '',
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTkyOTY0MDYsImV4cCI6MTcwNzA3MjQwNiwic3ViIjoiZmEzYzQ2NDktMTdmZi00Y2M4LWI5OGUtYTU5Y2M4MWEyOWU5In0.cMMSyGvrMsKTPjOlnwdooU08YcgDIOcj9Q56RRogRSw',
    invitationUrl:
      'http://localhost:3002/invite/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTkyOTY0MDYsImV4cCI6MTcwNzA3MjQwNiwic3ViIjoiZmEzYzQ2NDktMTdmZi00Y2M4LWI5OGUtYTU5Y2M4MWEyOWU5In0.cMMSyGvrMsKTPjOlnwdooU08YcgDIOcj9Q56RRogRSw',
    status: '',
    organisation: {
      logoUrl: 'https://placehold.co/400x400?text=Frontend%20Third%20Demo%20Authority',
      orgName: 'Frontend Third Demo Authority',
      __typename: 'OrganisationModel',
    },
  },
};

export const createUserWithInviteMutationResponse: CreateUserWithInviteMutation = {
  createUserWithInvite: {
    result: true,
  },
};

export const getNextDisclosureCycleResponse: GetNextDisclosureCycleQuery = {
  getNextDisclosureCycle: {
    disclosureCycleId: '43aa5-a405-421b-8185-aa8bd75f8428',
    name: 'Disclosure Cycle for 2024',
    startDate: '2024-04-01T12:45:50.311Z',
    endDate: '2024-12-30T12:45:50.311Z',
    deadlineToDisclose: '2021-12-31T12:45:50.311Z',
    submitDueDate: '2024-10-01T12:45:50.311Z',
    cycleMargin: 90,
    year: 2024,
  },
};

export const getFutureDisclosureCyclesResponse: GetFutureDisclosureCyclesQuery = {
  getFutureDisclosureCycles: [
    {
      disclosureCycleId: '0af43aa5-a405-421b-8185-aa8bd75fda60',
      name: 'Disclosure Cycle for 2024',
      year: 2024,
      __typename: 'DisclosureCycleModel',
    },
  ],
};

export const getCDPAdminUsersResponse: GetCdpAdminUsersQuery = {
  getCDPAdminUsers: [
    {
      displayName: 'CDP Admin User',
      id: 'cf1926b4-d95f-45bf-920e-bb5013c02f6b',
      __typename: 'UserModel',
    },
  ],
};

export const getAllTagsResponse: GetAllTagsQuery = {
  getAllTags: [
    {
      tagId: '115fd6a9-e358-478f-838e-c115e50edd86',
      name: '2020 Test Project',
      __typename: 'TagModel',
    },
  ],
};

export const getAuthorityTypesResponse: GetAuthorityTypesQuery = {
  getAuthorityTypes: [
    {
      authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
      name: 'Supply Chain',
      __typename: 'AuthorityTypeModel',
    },
    {
      authorityTypeId: AuthorityTypeIdService.C_STAR(),
      name: 'C-Star',
      __typename: 'AuthorityTypeModel',
    },
    {
      authorityTypeId: AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY(),
      name: 'Capital Markets Signatory',
      __typename: 'AuthorityTypeModel',
    },
  ],
};

export const getProjectInstancesByAuthorityResponse: GetProjectInstancesByAuthorityQuery = {
  getProjectInstancesByAuthority: {
    owned: [
      {
        project: {
          logoUrl: 'https://avatars.githubusercontent.com/u/84547728?s=200&v=4',
          description:
            'Green software is software that is responsible for emitting fewer greenhouse gases. Our focus is reduction, not neutralisation.',
          authorityOrganisation: {
            orgName: 'Frontend Demo Authority',
            logoUrl: 'https://placehold.co/400x400?text=Frontend%20Demo%20Authority',
            __typename: 'OrganisationModel',
          },
          __typename: 'ProjectModel',
        },
        subAuthority: null,
        projectInstanceType: 'Opt In With Confirmation',
        startDate: '2023-01-01T12:45:50.311Z',
        endDate: '2024-02-28T12:45:50.311Z',
        description: 'This is the instance for the cycle defined below 2023',
        authorityTypes: [
          {
            name: 'Capital Markets Signatory',
            __typename: 'AuthorityTypeModel',
          },
        ],
        isPublic: true,
        projectInstanceId: '0ec31a92-6abf-4f35-af9d-f2f055dc6b8c',
        name: 'Green Software Foundation - 2023',
        __typename: 'ProjectInstanceModel',
      },
      {
        project: {
          logoUrl:
            'https://www.connect4climate.org/sites/default/files/images/branding/Voices4Climate_logo-200x200.png',
          description:
            'The Voices for Just Climate Action alliance brings together global and local voices by connecting a diverse range of civil society organizations representing women, youth, indigenous people, urban poor, digital activists and more.',
          authorityOrganisation: {
            orgName: 'Frontend Demo Authority',
            logoUrl: 'https://placehold.co/400x400?text=Frontend%20Demo%20Authority',
            __typename: 'OrganisationModel',
          },
          __typename: 'ProjectModel',
        },
        subAuthority: null,
        projectInstanceType: 'Mandatory',
        startDate: '2023-01-01T12:45:50.311Z',
        endDate: '2024-02-28T12:45:50.311Z',
        description: 'This is the instance for the cycle defined below 2023',
        authorityTypes: [
          {
            name: 'C-Star',
            __typename: 'AuthorityTypeModel',
          },
          {
            name: 'Capital Markets Signatory',
            __typename: 'AuthorityTypeModel',
          },
        ],
        isPublic: true,
        projectInstanceId: '565ffa27-651e-47b9-a180-3ece4651bae4',
        name: 'Voices for Climate - 2023',
        __typename: 'ProjectInstanceModel',
      },
    ],
    viewOnly: [],
  },
};

export const getProjectInstancesResponse: GetProjectInstancesQuery = {
  getProjectInstances: {
    totalCount: 1,
    data: [
      {
        created_on: '2023-11-21T18:08:59.341Z',
        updated_on: '2023-11-21T18:08:59.341Z',
        createdBy: '7cb215f8-0064-4556-b4b6-98d66a74ef4a',
        updatedBy: null,
        projectInstanceId: '81b6d15c-8ecf-4b65-a65d-637eab4f8a9f',
        name: 'CDP TEST - 2024',
        projectId: '36b7a676-f4d6-4a0b-89b6-6ce98ec36b91',
        disclosureCycleId: '0af43aa5-a405-421b-8185-aa8bd75fda60',
        description: 'a',
        projectManagerId: 'cf1926b4-d95f-45bf-920e-bb5013c02f6b',
        startDate: '2023-12-31T23:00:00.000Z',
        endDate: '2024-12-30T23:00:00.000Z',
        isPublished: false,
        isPublic: true,
        subAuthorityId: null,
        // projectInstanceUrl: 'http://www',
        projectInstanceType: 'Mandatory',
        projectManager: {
          displayName: 'CDP Admin User',
          __typename: 'UserModel',
        },
        tags: [
          {
            tagGroup: {
              tags: [
                {
                  name: 'Asset managers',
                  __typename: 'TagModel',
                },
                {
                  name: 'Banks',
                  __typename: 'TagModel',
                },
                {
                  name: 'Insurance',
                  __typename: 'TagModel',
                },
                {
                  name: 'REIT',
                  __typename: 'TagModel',
                },
              ],
              __typename: 'TagGroupModel',
            },
            __typename: 'TagModel',
          },
        ],
        authorityTypes: [
          {
            name: 'C-Star',
            __typename: 'AuthorityTypeModel',
          },
        ],
        subAuthority: null,
        project: {
          name: 'CDP TEST',
          __typename: 'ProjectModel',
        },
        __typename: 'ProjectInstanceModel',
      },
    ],
    __typename: 'PaginatedProjectInstanceResult',
  },
};

export const getProjectInstanceByIdResponse: GetProjectInstanceByIdQuery = {
  getProjectInstanceById: {
    isPublished: false,
    projectManager: {
      id: 'testId',
      displayName: 'CDP Admin User',
    },
    startDate: '2023-12-31T23:00:00.000Z',
    endDate: '2024-12-30T23:00:00.000Z',
    projectInstanceType: 'Mandatory',
    isPublic: true,
    name: 'CDP TEST - 2024',
    subAuthority: {
      orgName: 'subAuthority',
      logoUrl: 'https://azure_blob_link.com',
    },
    project: {
      name: 'CDP TEST',
      logoUrl: 'https://statcdpgrnstrdisclosure.blob.core.windows.net/assets-public/project_logos/1700147402313.png',
      description:
        'Test longer project description Test longer project description Test longer project description Test longer project description Test longer project description Test longer project description Test longer project description Test longer project description Test longer project description ',
      authorityOrganisation: {
        orgName: 'Frontend Second Demo Authority',
      },
      mainAuthorityType: { name: 'Supply Chain' },
    },
    description: 'a',
    tags: [
      {
        name: 'Asset managers',
        tagId: 'dd90ae82-9fa3-4984-bab8-e97ee4515aef',
      },
    ],
    authorityTypes: [
      {
        authorityTypeId: AuthorityTypeIdService.C_STAR(),
        name: 'C-Star',
      },
    ],
    __typename: 'ProjectInstanceModel',
  },
};

export const addOrganisationToProjectInstanceResponse: AddOrganisationToProjectInstanceMutation = {
  addOrganisationToProjectInstance: [
    {
      projectInstanceId: '495396e5-bda2-4a5a-a411-0fdb58d663e8',
      discloserListOrganisation: null,
      __typename: 'ProjectInstanceOrganisationModel',
    },
  ],
};

const removeOrganisationsFromProjectInstanceResponse: RemoveOrganisationsFromProjectInstanceMutation = {
  removeOrganisationsFromProjectInstance: [
    {
      discloserListOrganisation: null,
      __typename: 'ProjectInstanceOrganisationModel',
    },
    {
      discloserListOrganisation: null,
      __typename: 'ProjectInstanceOrganisationModel',
    },
  ],
};

export const GetProjectInstanceOrganisationsResponse: GetProjectInstanceOrganisationsQuery = {
  getProjectInstanceOrganisations: {
    counts: {
      all: 2,
      noResponse: 2,
      activated: 0,
      submitted: 0,
    },
    data: [
      {
        disclosingOrganisationId: '5a03a35a-e094-4935-9bb4-8be95d97ca0e',
        organisation: {
          orgName: 'CRE,Inc.',
          OrgCountry: {
            countryName: 'Japan',
            __typename: 'CountryModel',
          },
          industry: {
            industryName: 'Services',
            __typename: 'IndustryModel',
          },
          __typename: 'OrganisationModel',
        },
        requestingAuthority: {
          orgName: 'Frontend Demo Authority',
          __typename: 'OrganisationModel',
        },
        questionnaire: {
          name: 'Corporate 2022',
          __typename: 'QuestionnaireModel',
        },
        status: 'Requested',
        projectAffiliation: {
          relationshipType: 'Pending',
          __typename: 'ProjectInstanceOrganisationModel',
        },
        __typename: 'DiscloserListOrganisationModel',
      },
      {
        disclosingOrganisationId: '7337a4c1-32a5-4677-9129-1d3064f5b3d7',
        organisation: {
          orgName: 'Ultrapar Participações S/A',
          OrgCountry: {
            countryName: 'Brazil',
            __typename: 'CountryModel',
          },
          industry: {
            industryName: 'Fossil fuels',
            __typename: 'IndustryModel',
          },
          __typename: 'OrganisationModel',
        },
        requestingAuthority: {
          orgName: 'Frontend Demo Authority',
          __typename: 'OrganisationModel',
        },
        questionnaire: {
          name: 'Corporate 2022',
          __typename: 'QuestionnaireModel',
        },
        status: 'Requested',
        projectAffiliation: {
          relationshipType: 'Pending',
          __typename: 'ProjectInstanceOrganisationModel',
        },
        __typename: 'DiscloserListOrganisationModel',
      },
    ],
    __typename: 'DiscloserListOrganisationResponseModel',
  },
};

export const getProjectInstanceSnapshotResponse: GetProjectInstanceSnapshotQuery = {
  getProjectInstanceSnapshot: [
    {
      name: 'Total',
      count: 9,
      percentage: 100,
      __typename: 'ResponseDistributionModel',
    },
    {
      name: 'No response',
      count: 6,
      percentage: 66.66666666666666,
      __typename: 'ResponseDistributionModel',
    },
    {
      name: 'Activated',
      count: 2,
      percentage: 22.22222222222222,
      __typename: 'ResponseDistributionModel',
    },
    {
      name: 'Submitted',
      count: 1,
      percentage: 11.11111111111111,
      __typename: 'ResponseDistributionModel',
    },
    {
      name: 'Amended',
      count: 0,
      percentage: 0,
      __typename: 'ResponseDistributionModel',
    },
    {
      name: 'Request not sent',
      count: 0,
      percentage: 0,
      __typename: 'ResponseDistributionModel',
    },
  ],
};

export const getSampleResponse: GetSampleQuery = {
  getSample: {
    name: 'Test Sample',
    authorityTypes: [
      {
        name: 'Type1',
        authorityTypeId: 'testId1',
      },
      {
        name: 'Type2',
        authorityTypeId: 'testId2',
      },
      {
        name: 'Type3',
        authorityTypeId: 'testId3',
      },
      {
        name: 'Type4',
        authorityTypeId: 'testId4',
      },
    ],
    snapshot: {
      activated: 0,
      noResponse: 2,
      submitted: 0,
      amended: 0,
      requestNotSent: 0,
      total: 2,
      responseRate: 100,
    },
    authorityType: {
      name: 'CSTAR',
    },
    trackingPortfolioId: '950c1152-4aa3-40e7-bee8-a29c9f8b08b0',
    isSample: true,
    isPublished: false,
    discloserListId: 'a60281ee-c885-4d34-915b-cb0886f16a9e',
    description: 'Sample for FTSE',
    authorityOrgId: '8',
  },
};

export const getSampleOrganisationsResponse: GetSampleOrganisationsQuery = {
  getSampleOrganisations: {
    counts: {
      all: 2,
      noResponse: 2,
      activated: 0,
      submitted: 0,
    },
    data: [
      {
        authorityReference: null,
        discloserListOrganisationId: '262f3cef-aeea-4a11-928f-4273c5a29f9e',
        status: 'Requested',
        response: {
          responseStatus: 'No Response',
          progress: 0,
          __typename: 'ResponseModel',
        },
        organisation: {
          orgName: 'La Banque Postale',
          ParentOrganisation: null,
          organisationId: 'e4ecbd5a-85cd-46cd-b762-f9ad4efb1469',
          OrgCountry: {
            countryName: 'France',
            __typename: 'CountryModel',
          },
          OrgHqCountry: {
            countryName: 'France',
            __typename: 'CountryModel',
          },
          LEI: null,
          ISIN: null,
          DUNS: '100002588',
          Ticker: null,
          BIC: null,
          industry: {
            industryName: 'Services',
            __typename: 'IndustryModel',
          },
          activityGroup: {
            activityGroupName: 'Financial services',
            __typename: 'ActivityGroupModel',
          },
          activity: {
            activityName: 'Banks',
            __typename: 'ActivityModel',
          },
          __typename: 'OrganisationModel',
        },
        disclosingOrganisation: {
          orgName: 'La Banque Postale',
          __typename: 'OrganisationModel',
        },
        __typename: 'DiscloserListOrganisationModel',
      },
      {
        authorityReference: null,
        discloserListOrganisationId: 'f80d6ba8-d62b-4c4e-b6be-d8e72b2d65cc',
        status: 'Requested',
        response: {
          responseStatus: 'No Response',
          progress: 0,
          __typename: 'ResponseModel',
        },
        organisation: {
          orgName: 'MAHLE GmbH',
          ParentOrganisation: null,
          organisationId: 'cdfd2cff-e814-4448-8444-81451f42d915',
          OrgCountry: {
            countryName: 'Germany',
            __typename: 'CountryModel',
          },
          OrgHqCountry: {
            countryName: 'Germany',
            __typename: 'CountryModel',
          },
          LEI: null,
          ISIN: null,
          DUNS: '100002728',
          Ticker: null,
          BIC: null,
          industry: {
            industryName: 'Manufacturing',
            __typename: 'IndustryModel',
          },
          activityGroup: {
            activityGroupName: 'Metal products manufacturing',
            __typename: 'ActivityGroupModel',
          },
          activity: {
            activityName: 'Fabricated metal components',
            __typename: 'ActivityModel',
          },
          __typename: 'OrganisationModel',
        },
        disclosingOrganisation: {
          orgName: 'MAHLE GmbH',
          __typename: 'OrganisationModel',
        },
        __typename: 'DiscloserListOrganisationModel',
      },
    ],
  },
};

export const IsSampleCdpAuthorityQueryResponse: IsSampleCdpAuthorityQuery = {
  isSampleCDPAuthority: true,
};

export const GetCycleSamplesByAuthorityTypeIdQueryResponse: GetCycleSamplesByAuthorityTypeIdQuery = {
  getCycleSamplesByAuthorityTypeId: {
    count: 2,
    data: [
      {
        authorityOrgId: '8',
        createdBy: '1',
        created_on: '2023-12-21T11:57:39.153Z',
        description:
          "The FTSE 100 broadly consists of the largest 100 qualifying UK companies by full market value.The total market value of a company is calculated by multiplying the share price of the company by the total number of shares they have issued.However, many of these are internationally focused companies: therefore the index's movements are a fairly weak indicator of how the UK economy is faring and are significantly affected by the exchange rates of the pound sterling. A better indication of the UK economy is the FTSE 250 Index, as it contains a smaller proportion of international companies.",
        discloserListId: '7ad6eac9-7330-4185-b1fb-ead506cc2efc',
        isPublished: true,
        isSample: true,
        name: 'FTSE 100',
        snapshot: {
          activated: 0,
          noResponse: 15,
          submitted: 0,
          amended: 0,
          requestNotSent: 0,
          total: 15,
          responseRate: 0,
          __typename: 'TrackingPortfolioSnapshotModel',
        },
        trackingPortfolioId: 'b52ff33a-7867-4eeb-b45f-8f92e62e9585',
        updatedBy: '091437aa-61c4-4c12-b669-097cc4ff9e81',
        updated_on: '2023-12-21T12:00:02.687Z',
        __typename: 'SampleModel',
      },
      {
        authorityOrgId: '8',
        createdBy: '1',
        created_on: '2023-12-21T12:07:45.245Z',
        description:
          "The CAC 40 (French pronunciation: [kak ka??~t]) (Cotation Assistée en Continu) is a benchmark French stock market index. The index represents a capitalization-weighted measure of the 40 most significant stocks among the 100 largest market caps on the Euronext Paris (formerly the Paris Bourse). It is a price return index. It is one of the main national indices of the pan-European stock exchange group Euronext alongside Euronext Amsterdam's AEX, Euronext Brussels' BEL20, Euronext Dublin's ISEQ 20, Euronext Lisbon's PSI-20 and the Oslo Bors OBX Index. It is an index without dividends. Cotation every working day from 9:00 a.m. to 5:30 p.m.. It is updated every 15 seconds.",
        discloserListId: '7ad6eac9-7330-4185-b1fb-ead506cc2efc',
        isPublished: true,
        isSample: true,
        name: 'CAC 40',
        snapshot: {
          activated: 0,
          noResponse: 15,
          submitted: 0,
          amended: 0,
          requestNotSent: 0,
          total: 15,
          responseRate: 0,
          __typename: 'TrackingPortfolioSnapshotModel',
        },
        trackingPortfolioId: '69b40b99-52e4-488e-b9aa-3ebfdd4ef0d4',
        updatedBy: '091437aa-61c4-4c12-b669-097cc4ff9e81',
        updated_on: '2023-12-21T12:08:02.765Z',
        __typename: 'SampleModel',
      },
    ],
    __typename: 'SamplesListModel',
  },
};

export const updateMyLanguagePreferencesResponse: UpdateMyLanguagePreferencesMutation = {
  updateMyLanguagePreferences: {
    id: 'dc3d25a3-7732-4f98-a99d-06222fedc2e2',
    __typename: 'UserModel',
  },
};
const createQuestionnaireSetupResponse: CreateQuestionnaireSetupMutation = {
  createQuestionnaireSetup: {
    disclosureCycleId: '53fa78f5-37a4-4994-bba9-b2a9d74d2c0b',
    organisationId: 'e7a71aee-65ac-4c0d-8442-e668c85973b8',
    questionnaireSetupId: 'd1cfa912-f3f2-444d-bbec-d98a9caa4125',
  },
};

export const updateActivityRevenuePercentagesResponse: UpdateActivityRevenuePercentagesMutation = {
  updateActivityRevenuePercentages: {
    questionnaireSetupSectorAllocations: [],
    questionnaireSetupId: 'd1cfa912-f3f2-444d-bbec-d98a9caa4125',
  },
};

const updateQuestionnaireSetupResponse: UpdateQuestionnaireSetupMutation = {
  updateQuestionnaireSetup: {
    questionnaireSetupId: 'd1cfa912-f3f2-444d-bbec-d98a9caa4125',
    organisationId: 'e7a71aee-65ac-4c0d-8442-e668c85973b8',
  },
};

export const getQuestionnaireSetupByOrgIdResponse: GetQuestionnaireSetupByOrgIdQuery = {
  getQuestionnaireSetupByOrgId: {
    questionnaireSetupId: 'd1cfa912-f3f2-444d-bbec-d98a9caa4125',
    isSubmitted: false,
    lastSubmittedOn: null,
    isReset: false,
  },
};

export const getQuestionnaireSetupResponse: GetQuestionnaireSetupQuery = {
  getQuestionnaireSetup: {
    annualRevenueFrom: new Date(),
    annualRevenue: 500000,
    numberOfFTE: 250,
    recommendedPathway: QuestionnaireSetupPathway.Sme,
    selectedPathway: null,
    elegiblePathway: QuestionnaireSetupPathway.Sme,
    activityRevenuePercentages: [
      {
        organisationActivityClassificationId: '85d92321-5284-4ea0-a632-21c96e4cab6a',
        activityRevenuePercentageId: '85d92321-5284-4ea0-a632-21c96e4cab6b',
        percentage: 80,
        themeByWaterImpactMethodology: false,
        themeByForestImpactMethodology: true,
        activity: {
          activityName: 'Non-residential building construction',
          forestImpact: ActivityImpactEnum.Critical,
          waterImpact: ActivityImpactEnum.Low,
          __typename: 'ActivityModel',
        },
        organisationActivityClassification: {
          organisationActivityClassificationId: '85d92321-5284-4ea0-a632-21c96e4cab6a',
          industry: {
            industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
            industryName: 'Infrastructure',
            __typename: 'IndustryModel',
          },
          activityGroup: {
            activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
            activityGroupName: 'Construction',
            __typename: 'ActivityGroupModel',
          },
          activity: {
            activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
            activityName: 'Non-residential building construction',
            questionnaireSector: {
              name: 'Construction',
              __typename: 'QuestionnaireSectorModel',
            },
            __typename: 'ActivityModel',
          },
          sortOrder: null,
          isPrimary: true,
          __typename: 'OrganisationActivityClassificationModel',
        },
        __typename: 'ActivityRevenuePercentageModel',
      },
    ],
    waterAuthorityRequested: false,
    forestAuthorityRequested: true,
    forestSelfAssessment: true,
    forestSelfIdentification: false,
    waterSelfAssessment: false,
    waterSelfIdentification: null,
    forestIntentToSubmit: null,
    waterIntentToSubmit: null,
    updated_on: '2023-06-19T13:54:40.387Z',
    conductMiningProjects: false,
    jurisdictionPopulation: null,
    perCapitaEmissions: null,
    humanDevelopmentIndex: null,
    citiesRecommendedPathway: null,
    citiesSelectedPathway: null,
    organisationActivityClassifications: [
      {
        organisationActivityClassificationId: '85d92321-5284-4ea0-a632-21c96e4cab6a',
        industry: {
          industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
          industryName: 'Infrastructure',
          __typename: 'IndustryModel',
        },
        activityGroup: {
          activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
          activityGroupName: 'Construction',
          __typename: 'ActivityGroupModel',
        },
        activity: {
          activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
          activityName: 'Non-residential building construction',
          questionnaireSector: {
            name: 'Construction',
            __typename: 'QuestionnaireSectorModel',
          },
          __typename: 'ActivityModel',
        },
        sortOrder: null,
        isPrimary: true,
        industryId: '4d461c6b-fd92-4265-8913-c6207c5be06d',
        activityGroupId: '9783f1f8-fa21-4455-a599-13da2868b8cc',
        activityId: '36687531-7ecc-47e4-ba65-6d3a1bc59d25',
        __typename: 'OrganisationActivityClassificationModel',
      },
    ],
    organisation: {
      isInvestorRequested: false,
      isPublicAuthority: false,
      hasNonPublicAuthoritiesRequests: false,
      isRequestedByCDPCapitalMarkets: false,
    },
    hasPreviousACSData: false,
    isReset: false,
    isSubmitted: false,
    lastSubmittedOn: null,
    cdpThemeAssessments: [
      {
        forestAssessment: null,
        waterAssessment: null,
        cdpThemeAssessmentId: '12345',
      },
    ],
    questionnaireSetupSectorAllocations: [],
  },
};

export const updateSampleResponse: UpdateSampleMutation = {
  updateSample: {
    name: 'Fernando Test',
    __typename: 'SampleModel',
  },
};

export const addDiscloserListOrganisationsToTrackingPortfolioResponse: AddDiscloserListOrganisationsToTrackingPortfolioMutation =
  {
    addDiscloserListOrganisationsToTrackingPortfolio: true,
  };

export const delegateRequestResponse: DelegateRequestMutation = {
  delegateRequest: true,
};

export const setResponseMappingResponse: SetResponseMappingMutation = {
  setResponseMapping: true,
};

export const getPhasesInformationResponse: GetPhasesInformationQuery = {
  getPhasesInformation: {
    endDate: '2023-12-31T12:45:50.311Z',
    phaseName: 'track',
    startDate: '2023-01-01T12:45:50.311Z',
    __typename: 'PhaseInformationModel',
  },
};

export const getOrgResponseByCycleResponse: GetOrgResponseByCycleQuery = {
  getOrgResponseByCycle: {
    __typename: 'ResponseModel',
    confirmIntention: true,
    responseStatus: ResponseStatusEnum.ACTIVATED,
  },
};

const publishSampleResponse: PublishSampleMutation = {
  publishSample: {
    trackingPortfolioId: 'TEST123',
    __typename: 'SampleModel',
  },
};

const unpublishSampleResponse: UnpublishSampleMutation = {
  unpublishSample: {
    trackingPortfolioId: 'TEST123',
    __typename: 'SampleModel',
  },
};

const deleteSampleResponse: DeleteSampleMutation = {
  deleteSample: {
    trackingPortfolioId: 'TEST123',
    __typename: 'SampleModel',
  },
};

const deleteOrganisationsFromSampleListResponse: DeleteOrganisationsFromSampleListMutation = {
  deleteOrganisationsFromSampleList: {
    createdBy: null,
    __typename: 'SampleOrganisationModel',
  },
};

export const getExtendedOrgSearchResponse: ExtendedOrgSearchQuery = {
  extendedOrgSearch: [
    {
      __typename: 'DnbOrganisationModel',
      orgName: 'Embotelladora Andina S.A.',
      DUNS: 'DUNS_1234',
      orgCountryName: 'Chile',
      parentOrgName: 'Embotelladora Andina S.A.',
      alpha2: 'CL',
    },
  ],
};

const getTodosResponse: GetTodosQuery = {
  getTodos: [
    {
      deadline: null,
      status: 'todo',
      name: 'chase_non_responders',
    },
    {
      deadline: null,
      status: 'progressing',
      name: 'engage_organisations',
    },
    {
      deadline: null,
      status: 'done',
      name: 'create_a_tracking_portfolio',
    },
  ],
};

export const getOrganisationAccountManagerResponse: GetOrganisationAccountManagerQuery = {
  getOrganisationAccountManager: {
    firstName: 'Supply',
    lastName: 'Chain',
    emailAddress: 'supply_chain_account_manager@example.com',
    __typename: 'AccountManagerModel',
  },
};

const getCommoditiesResponse: GetCommoditiesQuery = {
  getCommodities: [
    {
      commodityId: '868166d2-643e-4e8b-958c-eb2f5e37b44b',
      name: 'Timber products',
      questionnaireSetupCommodities: [],
      __typename: 'CommodityModel',
    },
  ],
};

const updateCommoditiesResponse: UpdateOrganisationDisclosureCommoditiesMutation = {
  updateOrganisationDisclosureCommodities: {
    questionnaireSetupId: 'd1cfa912-f3f2-444d-bbec-d98a9caa4125',
  },
};

const getOrganisationAuthorityTypesResponse: GetOrganisationAuthorityTypesQuery = {
  getOrganisationAuthorityTypes: [
    {
      authorityTypeId: AuthorityTypeIdService.SUPPLY_CHAIN(),
      name: 'Supply Chain',
      __typename: 'AuthorityTypeModel',
    },
    {
      authorityTypeId: AuthorityTypeIdService.CAPITAL_MARKETS_SIGNATORY(),
      name: 'Capital Markets Signatory',
      __typename: 'AuthorityTypeModel',
    },
  ],
};

const getNextDisclosureCycleForDiscloserResponse: GetNextDisclosureCycleForDiscloserQuery = {
  getNextDisclosureCycle: {
    disclosureCycleId: 'disclosureCycleId',
    name: 'Disclosure Cycle for 2024',
    startDate: '2024-04-01T12:45:50.311Z',
    endDate: '2024-12-30T12:45:50.311Z',
    submitDueDate: '2024-10-01T12:45:50.311Z',
    year: 2024,
  },
};

const getOrganisationInvoiceResponse: GetOrganisationInvoiceQuery = {
  getOrganisationInvoice: {
    invoiceId: 'invoiceId',
    organisationId: 'organisationId',
    status: 'status',
    totalInvoiceAmount: 1000,
    invoiceTaxPercentage: 10,
    totalTaxAmount: 100,
    invoiceProductItems: [],
  },
};

const getExistingProjectRequestsResponse: GetExistingProjectRequestsQuery = {
  getExistingProjectRequests: {
    totalRequests: 1,
    projectInstanceOrganisations: [
      {
        relationshipType: 'Opted In',
        projectInstance: {
          projectInstanceType: 'Opt In',
          project: {
            created_on: '2020-01-01T00:00:00.000Z',
            updated_on: '2020-01-01T00:00:00.000Z',
            createdBy: '1',
            updatedBy: null,
            projectId: '470bbaaa-6f86-40dd-ab19-6e219bf2d90d',
            name: 'Green Software Foundation',
            description:
              'Green software is software that is responsible for emitting fewer greenhouse gases. Our focus is reduction, not neutralisation.',
            logoUrl: 'url',
            authorityOrganisation: {
              orgName: 'Blue Software Foundation',
              logoUrl: 'url',
            },
          },
          projectInstanceTranslations: [
            {
              language: 'en',
              field: 'description',
              translation: 'test aw',
            },
          ],
        },
      },
    ],
  },
};

const getOptionalProjectRequestsResponse: GetOptionalProjectRequestsQuery = {
  getOptionalProjectRequests: {
    totalRequests: 1,
    importedProjectInstanceOrganisations: [
      {
        status: 'pending',
        importedProjectInstanceOrganisationId: '870bbaaa-6f86-40dd-ab19-6e219bf2d90f',
        projectInstance: {
          project: {
            created_on: '2020-01-01T00:00:00.000Z',
            updated_on: '2020-01-01T00:00:00.000Z',
            createdBy: '1',
            updatedBy: null,
            projectId: '470bbaaa-6f86-40dd-ab19-6e219bf2d90d',
            name: 'Green Software Foundation',
            description:
              'Green software is software that is responsible for emitting fewer greenhouse gases. Our focus is reduction, not neutralisation.',
            logoUrl: 'url',
            authorityOrganisation: {
              orgName: 'Blue Software Foundation',
              logoUrl: 'url',
            },
          },
        },
      },
    ],
  },
};

const joinOptionalProjectResponse: JoinOptionalProjectMutation = {
  joinOptionalProjectInstanceFromRequest: {
    importedProjectInstanceOrganisationId: '894ac0a6-1392-40dd-bcb9-55030285f2da',
  },
};

const getImportedProjectInstanceOrganisations: GetImportedProjectInstanceOrganisationsQuery = {
  getImportedProjectInstanceOrganisations: {
    count: 0,
    data: [],
  },
};

const getDisclosingOrganisationResponse: GetDisclosingOrganisationQuery = {
  getDisclosingOrganisation: {
    DUNS: 'DUNS_1234',
    orgName: 'Embotelladora Andina S.A.',
    organisationId: 'bdji23-65ac-4c0d-8442-e668c85973b8',
  },
};

const getRequestersResponse: GetRequestersQuery = {
  getRequesters: [
    {
      status: 'pending',
      title: 'Disclosure Cycle for 2024 Supply Chain CDP Discloser List',
      authorityOrg: {
        orgName: 'CDP',
        organisationId: '4bdfb362-a181-e711-8134-000d3a276ebc',
        organisationNo: 5,
      },
    },
  ],
};

const getActiveRequestersResponse: GetActiveRequestersQuery = {
  getActiveRequesters: [
    {
      status: 'active',
      title: 'Disclosure Cycle for 2024 Supply Chain CDP Discloser List',
    },
  ],
};

const getResponseByOrganisationIdResponse: GetResponseByOrganisationIdQuery = {
  getResponseByOrganisationId: {
    responseId: '1',
    organisationId: '1',
    extensionWeeks: 1,
    extensionReason: 'ods',
  },
};

const getExistingProjectRequestsNumberResponse: GetExistingProjectRequestsNumberQuery = {
  getExistingProjectRequests: {
    totalRequests: 2,
  },
};

const getDisclosureCycleByIdResponse: GetDisclosureCycleByIdQuery = {
  getDisclosureCycleById: {
    startDate: '2023-01-01T12:45:50.311Z',
    endDate: '2023-12-30T12:45:50.311Z',
    disclosureCycleId: '34cd9b53-1cce-4c40-9edd-1f41fb86d59d',
    year: 2023,
    name: 'Disclosure Cycle for 2023',
  },
};

const checkIfOrgsInTheProjectResponse: CheckIfOrgsInTheProjectQuery = {
  checkIfOrgsInTheProject: [
    {
      result: true,
      discloserListOrganisation: {
        discloserListOrganisationId: '1',
        organisationId: '1',
      },
    },
  ],
};

export const getUserTasksMock = discloserServer.query('GetAuthorityUserTasks', (_, res, ctx) => {
  return res(ctx.data(getUserTasks));
});

export const checkIfOrgsInTheProjectMock = discloserServer.query('CheckIfOrgsInTheProject', (_, res, ctx) => {
  return res(ctx.data(checkIfOrgsInTheProjectResponse));
});

export const getOrganisationMock = discloserServer.query('GetAllAuthorityDiscloserLists', (_, res, ctx) => {
  return res(ctx.data(getAllDiscloserListsResponse));
});
export const getAllDiscloserListsMock = discloserServer.query('GetOrganisationByIdentifier', (_, res, ctx) => {
  return res(ctx.data(getOrganisationResponse));
});
export const getDiscloserMock = discloserServer.query('GetDiscloserListByIdentifier', (_, res, ctx) => {
  return res(ctx.data(getDiscloserResponse));
});
export const getDiscloserLateRequestsMock = discloserServer.query('GetDiscloserLateRequests', (_, res, ctx) => {
  return res(ctx.data(getDicloserListLateRequestsResponse));
});
export const getDiscloserListFilterOptionsMock = discloserServer.query(
  'GetDiscloserListFilterOptions',
  (_, res, ctx) => {
    return res(ctx.data(getDiscloserListFilterOptionsResponse));
  },
);
export const getSearchOrganisationMock = discloserServer.query('SearchOrganisation', (_, res, ctx) => {
  return res(ctx.data(getSearchOrganisationResponse));
});
export const addDiscloserMock = discloserServer.mutation('AddToDiscloserList', (_, res, ctx) => {
  return res(ctx.data(addDiscloserResponse));
});
export const removeDiscloserMock = discloserServer.mutation('RemoveDiscloserFromList', (_, res, ctx) => {
  return res(ctx.data(removeDiscloserResponse));
});
export const createDiscloserListMock = discloserServer.mutation('CreateDiscloserList', (_, res, ctx) => {
  return res(ctx.data(createDiscloserListInputResponse));
});
export const submitDiscloserMock = discloserServer.mutation('SubmitDiscloserList', (_, res, ctx) => {
  return res(ctx.data(submitDiscloserResponse));
});
export const getCopiableDiscloserListsMock = discloserServer.query('GetCopiableDiscloserLists', (_, res, ctx) => {
  return res(ctx.data(getCopiableDiscloserListsResponse));
});

export const searchCountryMock = discloserServer.query('SearchCountry', (_, res, ctx) => {
  return res(ctx.data(searchCountryResponse));
});

export const getAllIndustriesMock = discloserServer.query('GetAllIndustries', (_, res, ctx) => {
  return res(ctx.data(getAllIndustriesResponse));
});

export const checkIfOrgsInTheListMock = discloserServer.query('CheckIfOrgsInTheList', (_, res, ctx) => {
  return res(ctx.data(checkIfOrgsInTheListResponse));
});

export const getActiveDisclosureCycleMock = discloserServer.query('GetActiveDisclosureCycle', (_, res, ctx) => {
  return res(ctx.data(getActiveDisclosureCycleResponse));
});

export const getActiveDisclosureCycleForDiscloserMock = discloserServer.query(
  'GetActiveDisclosureCycleForDiscloser',
  (_, res, ctx) => {
    return res(ctx.data(getActiveDisclosureCycleForDiscloserResponse));
  },
);

export const getIsRequestedByCmsMock = discloserServer.query('GetIsInvestorRequestedByCMS', (_, res, ctx) => {
  return res(ctx.data(getIsRequestedByCmsResponse));
});

export const getDiscloserListForCycleMock = discloserServer.query('GetDiscloserListForCycle', (_, res, ctx) => {
  return res(ctx.data(getDiscloserListForCycleResponse));
});

export const resetListMock = discloserServer.mutation('ResetList', (_, res, ctx) => {
  return res(ctx.data({ resetList: true }));
});

export const delegateRequestMock = discloserServer.mutation('DelegateRequest', (_, res, ctx) => {
  return res(ctx.data(delegateRequestResponse));
});

export const setResponseMappingMock = discloserServer.mutation('SetResponseMapping', (_, res, ctx) => {
  return res(ctx.data(setResponseMappingResponse));
});

export const getTopCountriesMock = discloserServer.query('TopCountries', (_, res, ctx) => {
  return res(ctx.data(getTopCountriesResponse));
});

export const getTopIndustriesMock = discloserServer.query('TopIndustries', (_, res, ctx) => {
  return res(ctx.data(getTopIndustriesResponse));
});

export const getImportListReportsMock = discloserServer.query('GetImportListReports', (_, res, ctx) => {
  return res(ctx.data(getImportListReportsResponse));
});

export const getImportListTemplateMock = discloserServer.query('GetImportListTemplate', (_, res, ctx) => {
  return res(ctx.data(getImportListTemplateReponse));
});

export const updateAuthorityReferenceMock = discloserServer.mutation('UpdateAuthorityReference', (_, res, ctx) => {
  return res(ctx.data(updateAuthorityReferenceResponse));
});

export const updateDiscloserEmailMock = discloserServer.mutation('UpdateDiscloserEmail', (_, res, ctx) => {
  return res(ctx.data(updateDiscloserEmailResponse));
});

export const getOrganisationFilterOptionsMock = discloserServer.query('GetOrganisationFilterOptions', (_, res, ctx) => {
  return res(ctx.data(getOrganisationFilterOptionsResponse));
});

export const updateDiscloserListThemesMock = discloserServer.mutation(
  'UpdateDiscloserListOrganisationThemes',
  (_, res, ctx) => {
    return res(ctx.data(updateDiscloserListThemesResponse));
  },
);

export const updateOrgDetailsMock = discloserServer.mutation('UpdateOrganisationDetails', (_, res, ctx) => {
  return res(ctx.data(updateOrgDetailsResponse));
});

export const getMyOrganisationDetailsMock = discloserServer.query('GetMyOrganisationDetails', (_, res, ctx) => {
  return res(ctx.data(getMyOrganisationDetailsResponse));
});

export const getDiscloserListExportFileLinkMock = discloserServer.query(
  'GetDiscloserListExportFileLink',
  (_, res, ctx) => {
    return res(ctx.data(getDiscloserListExportFileLinkResponse));
  },
);

export const checkIfCanBeScoredMock = discloserServer.query('CheckIfCanBeScored', (_, res, ctx) => {
  return res(ctx.data(checkIfCanBeScoredResponse));
});

export const getMyDetailsMock = discloserServer.query('GetMyDetails', (_, res, ctx) => {
  return res(ctx.data(getMyDetailsResponse));
});

export const getResponseRateStatsMock = discloserServer.query('GetResponseRateStats', (_, res, ctx) => {
  return res(ctx.data(getResponseRateStatsResponse));
});

export const MyRequestsMock = discloserServer.query('MyRequests', (_, res, ctx) => {
  return res(ctx.data(MyRequestsResponse));
});

export const MyRequestsTotalRequestersMock = discloserServer.query('MyRequestsTotalRequesters', (_, res, ctx) => {
  return res(ctx.data(MyRequestsResponse));
});

export const GetDelegatedRequestsMock = discloserServer.query('GetDelegatedRequests', (_, res, ctx) => {
  return res(ctx.data(getDelegatedRequestsResponse));
});

export const mySubsidiaryRequestsMock = discloserServer.query('GetSubsidiaryRequests', (_, res, ctx) => {
  return res(ctx.data(mySubsidiaryRequestsResponse));
});

export const getSubsidiaryRequestDetailsMock = discloserServer.query('GetSubsidiaryRequestDetails', (_, res, ctx) => {
  return res(ctx.data(getSubsidiaryRequestDetailsResponse));
});

export const getAllTagCollectionsMock = discloserServer.query('GetAllTagCollections', (_, res, ctx) => {
  return res(ctx.data(GetAllTagCollectionsRespose));
});

export const getTagExportFileLinkMock = discloserServer.query('GetTagExportFileLink', (_, res, ctx) => {
  return res(ctx.data(getTagExportFileLinkResponse));
});

export const createTrackingPortfolioMock = discloserServer.mutation('CreateTrackingPortfolio', (_, res, ctx) => {
  return res(ctx.data(createTrackingPortfolioResponse));
});

export const getDiscloserListTrackingPortfoliosMock = discloserServer.query(
  'GetDiscloserListTrackingPortfolios',
  (_, res, ctx) => {
    return res(ctx.data(getDiscloserListTrackingPortfoliosResponse));
  },
);

export const getTrackingPorfolioByIdMock = discloserServer.query(
  'GetTrackingPortfolioExportFileLink',
  (_, res, ctx) => {
    return res(ctx.data(getTrackingPortfolioExportFileLinkResponse));
  },
);

export const getTrackingPortfolioExportFileLinkMock = discloserServer.query(
  'GetTrackingPorfolioById',
  (_, res, ctx) => {
    return res(ctx.data(getTrackingPorfolioByIdResponse));
  },
);

export const deleteTrackingPortfolioMock = discloserServer.mutation('DeleteTrackingPortfolio', (_, res, ctx) => {
  return res(ctx.data(deleteTrackingPortfolioResponse));
});

export const createProjectMock = discloserServer.mutation('CreateProject', (_, res, ctx) => {
  return res(ctx.data(createProjectResponse));
});

export const getProjectsMock = discloserServer.query('GetProjects', (_, res, ctx) => {
  return res(ctx.data(getProjectsResponse));
});

export const getOrganisationUsersMock = discloserServer.query('GetOrganisationUsers', (_, res, ctx) => {
  return res(ctx.data(getOrganisationUsersResponse));
});

export const getAvailableRolesMock = discloserServer.query('GetAvailableRoles', (_, res, ctx) => {
  return res(ctx.data(getAvailableRolesResponse));
});

export const createInvitationMock = discloserServer.mutation('CreateInvitation', (_, res, ctx) => {
  return res(ctx.data(createInvitationResponse));
});

export const updateUserDetailsMock = discloserServer.mutation('UpdateUserDetails', (_, res, ctx) => {
  return res(ctx.data(updateUserDetailsResponse));
});

export const updateResponseExtensionMock = discloserServer.mutation('UpdateResponseExtension', (_, res, ctx) => {
  return res(ctx.data(updateResponseExtensionResponse));
});

export const disableUserMock = discloserServer.mutation('DisableUser', (_, res, ctx) => {
  return res(ctx.data(disableUserResponse));
});

export const getProjectByIdMock = discloserServer.query('GetProjectById', (_, res, ctx) => {
  return res(ctx.data(getProjectByIdResponse));
});

export const updateProjectMock = discloserServer.mutation('UpdateProject', (_, res, ctx) => {
  return res(ctx.data(updateProjectResponse));
});

export const deleteProjectMock = discloserServer.mutation('DeleteProject', (_, res, ctx) => {
  return res(ctx.data(deleteProjectResponse));
});

export const deleteProjectInstanceMock = discloserServer.mutation('DeleteProjectInstance', (_, res, ctx) => {
  return res(ctx.data(deleteProjectInstanceResponse));
});

export const publishProjectInstanceMock = discloserServer.mutation('PublishProjectInstance', (_, res, ctx) => {
  return res(ctx.data(publishProjectInstanceResponse));
});

export const unpublishProjectInstanceMock = discloserServer.mutation('UnpublishProjectInstance', (_, res, ctx) => {
  return res(ctx.data(unpublishProjectInstanceResponse));
});

export const getInviteByJwtMock = discloserServer.query('GetInviteByJwt', (_, res, ctx) => {
  return res(ctx.data(getInviteByJwtResponse));
});

export const createUserWithInviteMock = discloserServer.mutation('CreateUserWithInvite', (_, res, ctx) => {
  return res(ctx.data(createUserWithInviteMutationResponse));
});

export const getNextDisclosureCycleMock = discloserServer.query('GetNextDisclosureCycle', (_, res, ctx) => {
  return res(ctx.data(getNextDisclosureCycleResponse));
});

export const getProjectInstancesMock = discloserServer.query('GetProjectInstances', (_, res, ctx) => {
  return res(ctx.data(getProjectInstancesResponse));
});

export const getFutureDisclosureCyclesMock = discloserServer.query('GetFutureDisclosureCycles', (_, res, ctx) => {
  return res(ctx.data(getFutureDisclosureCyclesResponse));
});

export const getCDPAdminUsersMock = discloserServer.query('GetCDPAdminUsers', (_, res, ctx) => {
  return res(ctx.data(getCDPAdminUsersResponse));
});

export const getAllTagsMock = discloserServer.query('GetAllTags', (_, res, ctx) => {
  return res(ctx.data(getAllTagsResponse));
});

export const getAuthorityTypesMock = discloserServer.query('GetAuthorityTypes', (_, res, ctx) => {
  return res(ctx.data(getAuthorityTypesResponse));
});

export const getProjectInstanceByIdMock = discloserServer.query('GetProjectInstanceById', (_, res, ctx) => {
  return res(ctx.data(getProjectInstanceByIdResponse));
});

export const getProjectInstancesByAuthorityMock = discloserServer.query(
  'GetProjectInstancesByAuthority',
  (_, res, ctx) => {
    return res(ctx.data(getProjectInstancesByAuthorityResponse));
  },
);

export const addOrganisationToProjectInstanceMock = discloserServer.mutation(
  'AddOrganisationToProjectInstance',
  (_, res, ctx) => {
    return res(ctx.data(addOrganisationToProjectInstanceResponse));
  },
);

export const getProjectInstanceOrganisationsMock = discloserServer.query(
  'GetProjectInstanceOrganisations',
  (_, res, ctx) => {
    return res(ctx.data(GetProjectInstanceOrganisationsResponse));
  },
);

export const getProjectInstanceSnapshotMock = discloserServer.query('GetProjectInstanceSnapshot', (_, res, ctx) => {
  return res(ctx.data(getProjectInstanceSnapshotResponse));
});

export const removeOrganisationsFromProjectInstanceMock = discloserServer.mutation(
  'RemoveOrganisationsFromProjectInstance',
  (_, res, ctx) => {
    return res(ctx.data(removeOrganisationsFromProjectInstanceResponse));
  },
);

export const updateMyLanguagePreferencesMock = discloserServer.mutation(
  'UpdateMyLanguagePreferences',
  (_, res, ctx) => {
    return res(ctx.data(updateMyLanguagePreferencesResponse));
  },
);

export const getSampleMock = discloserServer.query('GetSample', (_, res, ctx) => {
  return res(ctx.data(getSampleResponse));
});

export const getSampleOrganisationsMock = discloserServer.query('GetSampleOrganisations', (_, res, ctx) => {
  return res(ctx.data(getSampleOrganisationsResponse));
});

export const createQuestionnaireSetupMock = discloserServer.mutation('CreateQuestionnaireSetup', (_, res, ctx) => {
  return res(ctx.data(createQuestionnaireSetupResponse));
});

export const updateQuestionnaireSetupMock = discloserServer.mutation('UpdateQuestionnaireSetup', (_, res, ctx) => {
  return res(ctx.data(updateQuestionnaireSetupResponse));
});

export const getQuestionnaireSetupMock = discloserServer.query('GetQuestionnaireSetup', (_, res, ctx) => {
  return res(ctx.data(getQuestionnaireSetupResponse));
});

export const getPhasesInformationMock = discloserServer.query('GetPhasesInformation', (_, res, ctx) => {
  return res(ctx.data(getPhasesInformationResponse));
});

export const addDiscloserListOrganisationsToTrackingPortfolioMock = discloserServer.mutation(
  'AddDiscloserListOrganisationsToTrackingPortfolio',
  (_, res, ctx) => {
    return res(ctx.data(addDiscloserListOrganisationsToTrackingPortfolioResponse));
  },
);

export const getSampleResponseRateMock = discloserServer.query('GetSampleResponseRate', (_, res, ctx) => {
  return res(ctx.data(getSampleResponseRateResponse));
});

export const getTopCountriesForSampleMock = discloserServer.query('GetTopCountriesForSample', (_, res, ctx) => {
  return res(ctx.data(getTopCountriesForSampleResponse));
});

export const getTopIndustriesForSampleMock = discloserServer.query('GetTopIndustriesForSample', (_, res, ctx) => {
  return res(ctx.data(getTopIndustriesForSampleResponse));
});
export const getSampleCdpAuthorityMock = discloserServer.query('IsSampleCDPAuthority', (_, res, ctx) => {
  return res(ctx.data(IsSampleCdpAuthorityQueryResponse));
});

export const getSampleByAuthorityTypeIdMock = discloserServer.query(
  'GetCycleSamplesByAuthorityTypeId',
  (_, res, ctx) => {
    return res(ctx.data(GetCycleSamplesByAuthorityTypeIdQueryResponse));
  },
);

export const publishSampleMock = discloserServer.mutation('PublishSample', (_, res, ctx) => {
  return res(ctx.data(publishSampleResponse));
});

export const unpublishSampleMock = discloserServer.mutation('UnpublishSample', (_, res, ctx) => {
  return res(ctx.data(unpublishSampleResponse));
});

export const deleteSampleMock = discloserServer.mutation('DeleteSample', (_, res, ctx) => {
  return res(ctx.data(deleteSampleResponse));
});

export const deleteOrganisationsFromSampleListMock = discloserServer.mutation(
  'DeleteOrganisationsFromSampleList',
  (_, res, ctx) => {
    return res(ctx.data(deleteOrganisationsFromSampleListResponse));
  },
);

export const getExtendedOrgSearchMock = discloserServer.query('ExtendedOrgSearch', (_, res, ctx) => {
  return res(ctx.data(getExtendedOrgSearchResponse));
});

export const updateSampleMock = discloserServer.mutation('UpdateSample', (_, res, ctx) => {
  return res(ctx.data(updateSampleResponse));
});

export const getTodosMock = discloserServer.query('GetTodos', (_, res, ctx) => {
  return res(ctx.data(getTodosResponse));
});

export const getOrganisationAccountManagerMock = discloserServer.query(
  'GetOrganisationAccountManager',
  (_, res, ctx) => {
    return res(ctx.data(getOrganisationAccountManagerResponse));
  },
);

export const getCommoditiesMock = discloserServer.query('GetCommodities', (_, res, ctx) => {
  return res(ctx.data(getCommoditiesResponse));
});

export const updateCommoditiesMock = discloserServer.mutation(
  'UpdateOrganisationDisclosureCommodities',
  (_, res, ctx) => {
    return res(ctx.data(updateCommoditiesResponse));
  },
);

export const getOrganisationAuthorityTypesMock = discloserServer.query(
  'GetOrganisationAuthorityTypes',
  (_, res, ctx) => {
    return res(ctx.data(getOrganisationAuthorityTypesResponse));
  },
);

export const getNextDisclosureCycleForDiscloserMock = discloserServer.query(
  'GetNextDisclosureCycleForDiscloser',
  (_, res, ctx) => {
    return res(ctx.data(getNextDisclosureCycleForDiscloserResponse));
  },
);

export const getExistingProjectRequestsMock = discloserServer.query('GetExistingProjectRequests', (_, res, ctx) => {
  return res(ctx.data(getExistingProjectRequestsResponse));
});

export const getOptionalProjectRequestsMock = discloserServer.query('GetOptionalProjectRequests', (_, res, ctx) => {
  return res(ctx.data(getOptionalProjectRequestsResponse));
});

export const joinOptionalProjectMock = discloserServer.mutation('JoinOptionalProject', (_, res, ctx) => {
  return res(ctx.data(joinOptionalProjectResponse));
});
export const getImportedProjectInstanceOrganisationsMock = discloserServer.query(
  'GetImportedProjectInstanceOrganisations',
  (_, res, ctx) => {
    return res(ctx.data(getImportedProjectInstanceOrganisations));
  },
);

export const getOrganisationInvoiceMock = discloserServer.query('GetOrganisationInvoice', (_, res, ctx) => {
  return res(ctx.data(getOrganisationInvoiceResponse));
});

export const top5CountriesMock = discloserServer.query('Top5Countries', (_, res, ctx) => {
  return res(ctx.data(top5CountriesResponse));
});

export const getDisclosingOrganisationMock = discloserServer.query('GetDisclosingOrganisation', (_, res, ctx) => {
  return res(ctx.data(getDisclosingOrganisationResponse));
});

export const getRequestersMock = discloserServer.query('GetRequesters', (_, res, ctx) => {
  return res(ctx.data(getRequestersResponse));
});

export const getActiveRequestersMock = discloserServer.query('GetActiveRequesters', (_, res, ctx) => {
  return res(ctx.data(getActiveRequestersResponse));
});

export const getResponseByOrganisationIdMock = discloserServer.query('GetResponseByOrganisationId', (_, res, ctx) => {
  return res(ctx.data(getResponseByOrganisationIdResponse));
});

export const getOrgResponseByCycleMock = discloserServer.query('GetOrgResponseByCycle', (_, res, ctx) => {
  return res(ctx.data(getOrgResponseByCycleResponse));
});

export const getMyOrgCountryResponseMock = discloserServer.query('GetMyOrgCountry', (_, res, ctx) => {
  return res(ctx.data(getMyOrgCountryResponse));
});

export const getQuestionnaireSetupByOrgIdMock = discloserServer.query('GetQuestionnaireSetupByOrgId', (_, res, ctx) => {
  return res(ctx.data(getQuestionnaireSetupResponse));
});

export const getExistingProjectRequestsNumberMock = discloserServer.query(
  'GetExistingProjectRequestsNumber',
  (_, res, ctx) => {
    return res(ctx.data(getExistingProjectRequestsNumberResponse));
  },
);

export const getDisclosureCycleByIdMock = discloserServer.query('GetDisclosureCycleById', (_, res, ctx) => {
  return res(ctx.data(getDisclosureCycleByIdResponse));
});
